.main {
    &__info {
        max-width: 730px;
        padding: 60px 0;
        margin-left: 183px;
        @include tablet1024 {
            margin-left: 40px;
            padding: 50px 0;
        }
        @include mobile576 {
            padding: 37px 0 30px;
            margin: 0 20px;
        }
        @include mobile375 {
            margin: 0;
        }

        &--russian {
            .main__text {
                @include font-size(17, 32);
            }

            @include response(767) {
                padding: 37px 0 30px;
                margin: 0 20px;
                padding-top: 0;
            }

            @include mobile576 {
                padding: 37px 0 30px;
                margin: 0 20px;
                padding-top: 0;
            }
        }
    }

    &__logo {
        margin-bottom: 30px;

        img {
            width: 190px;
            @include mobile576 {
                width: 180px;
            }
        }
    }

    &__title {
        font-weight: 700;
        margin-bottom: 16px;
        @include font-size(22, 33);
    }

    &__text {
        @include font-size(20, 34);

        &:not(:last-child) {
            margin-bottom: 20px;
            @include mobile576 {
                margin-bottom: 15px;
            }
        }
    }

    &__grid-wrap {
        padding: 50px 0 90px;
        background-color: $white-500;
        @include tablet1024 {
            padding-bottom: 60px;
        }
        @include mobile576 {
            padding: 40px 0 50px;
        }

        .container {
            max-width: calc(1145px + 30px);
            @include tablet1024 {
                max-width: calc(100% - 80px);
            }
            @include mobile576 {
                max-width: calc(100% - 30px);
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        @include tablet1024 {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        @include flex-center-vert;
        max-height: 286px;
        background-color: $white;
        box-shadow: 0 4px 20px rgba(68, 68, 83, 16%);
        @include tablet1024 {
            margin: 0 60px;
        }
        @include mobile576 {
            flex-direction: column;
            align-items: flex-start;
            max-height: none;
            margin: 0;
        }
    }
}

.card {

    &__cover {
        @include flex(row, wrap, center, center);
        width: 260px;
        height: 286px;
        background: #e30613;
        margin-right: 26px;

        svg {
            width: auto;
            height: auto;
            flex: none;
        }

        @include response(767) {
            margin-right: 0;
            height: 180px;
            width: 100%;
            margin-bottom: 17px;
        }
    }
    &__img {
        width: 260px;
        height: 286px;
        margin-right: 26px;
        font-size: 0;
        @include response(767) {
            margin-right: 0;
            max-width: 100%;
            height: 180px;
            width: 100%;
            margin-bottom: 17px;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &__text-wrap {
        @include mobile576 {
            padding: 0 25px 25px;
        }
    }

    &__title {
        margin-bottom: 24px;
        @include font-size(28, 34);
        font-weight: 400;
        color: $red-400;
        @include mobile576 {
            margin-bottom: 6px;
        }
    }

    &__link-text {
        @include flex(row, nowrap, flex-start, center);
        color: $dark-gray-600;
        @include font-size(16, 34);
        font-weight: 500;
        transition: all .3s ease-in-out;

        .svg-slash {
            width: 9px;
            height: 12px;
            margin-right: 5px;
            fill: $red-200;
        }

        &:hover {
            color: $red-500;
        }
    }
}