.card-chapter {
  @include flex(row, nowrap, space-between, stretch);
  margin-bottom: 70px;
  width: 100%;
  max-width: 1000px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background: #fff;

  &:first-child {
    margin-top: -25px;
  }

  &__cover {
    width: 350px;
    min-height: 442px;
    object-fit: cover;

    @include response(1023) {
      width: 264px;
      min-height: 497px;
    }

    @include response(767) {
      order: 0;
      width: 100%;
      height: 180px;
      min-height: 180px;
    }
  }

  &__section {
    padding: 60px;
    width: calc(100% - 350px);

    @include response(1023) {
      padding: 60px 70px 60px 35px;
      width: calc(100% - 264px);
    }

    @include response(767) {
      order: 1;
      padding: 30px;
      width: 100%;
    }
  }

  &__title {
    @include flex(row, nowrap, flex-start, center);
    position: relative;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: normal;

    &::after {
      content: '\00a0–';
      display: block;
      color: $red-400;
    }

    @include response(1023) {
      margin-bottom: 25px;
    }

    @include response(767) {
      margin-bottom: 10px;
    }
  }

  &__headline {
    margin-bottom: -25px;
    margin-left: 50px;
    font-weight: 900;
    font-size: 64px;
    line-height: 50px;
    color: rgba(49, 76, 110, 0.05);

    @include response(1023) {
      display: none;
    }
  }

  &__caption {
    font-size: 28px;
    line-height: normal;
    color: $red-400;
  }

  &__description {
    margin-top: 22px;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    text-align: left;
    color: #314c6e;
  }

  &__link {
    margin-top: 60px;
    @include response(1023) {
      margin-top: 60px;
    }

    @include response(767) {
      margin-top: 50px;
    }
    @extend %btn;
  }

  @include response(1023) {
    margin-bottom: 35px;
    width: 688px;
  }

  @include response(767) {
    @include flex(column, nowrap, flex-start, flex-start);

    width: calc(100% - 30px);
    margin-bottom: 25px;

    &:first-child {
      margin-top: -45px;
    }
  }
}
