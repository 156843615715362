.search-result {
  width: 100%;

  &__wrapper {
    padding: 20px 0 100px;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;

    @include response(1023) {
      max-width: 100%;
      padding: 15px 40px 40px;
    }

    @include response(767) {
      padding: 5px 40px 40px;
    }
    @include response(374) {
      padding: 5px 20px 40px;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 300;
    color: $red-500;
    @include response(767) {
      @include font-size(38, 48);
    }
  }

  &__content {
    padding: 0 90px;
    width: 100%;

    @include response(1023) {
      padding: 0;
    }
  }

  &__count {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    color: #314c6e;
    margin-bottom: 55px;

    @include response(767) {
      margin-bottom: 15px;
    }
  }

  &__item {
    display: block;
    border-bottom: 1px solid #e5e5e5;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
    @include desktop-hover {
      .search-result__caption {
        text-decoration: underline;
        color: $red-400;
      }
    }
  }

  &__heading {
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    color: $red-400;
    margin-bottom: 5px;
  }

  &__description {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 26px;
    margin-bottom: 10px;
  }

  &__caption {
    transition: all $transition;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 26px;
    color: #235190;
    margin-bottom: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__field {
    position: relative;
    width: 100%;
    height: 80px;
    margin-bottom: 50px;

    @include response(767) {
      height: 45px;
      margin-bottom: 35px;
    }
  }

  &__input {
    outline: none;
    padding: 0 30px;
    padding-right: 60px;
    width: 100%;
    height: 100%;
    border: 2px solid $red-400;
    color: #10384f;

    &:focus {
      border: 2px solid $red-400;
    }

    @include response(767) {
      padding: 0 50px 0 15px;
    }
  }
  &__button {
    transform: translate3d(0, -50%, 0);
    position: absolute;
    right: 25px;
    top: 50%;
    width: 28px;
    height: 28px;
    color: $red-400;
    font-size: 17px;
    line-height: 32px;

    svg {
      width: 100%;
      height: 100%;
    }

    @include response(767) {
      right: 15px;
    }
  }
}