.activity {
    &__text-wrap {
        max-width: 716px;
        padding-top: 60px;
        margin-left: 184px;
        @include tablet1024 {
            max-width: 572px;
            margin-left: 40px;
            padding-top: 50px;
        }
        @include mobile576 {
            padding-top: 40px;
            margin-left: 20px;
            margin-right: 20px;
        }
        @include mobile375 {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__btn {
        @extend %btn;
        margin-top: 25px;
    }

    &__subtitle {
        margin-bottom: 20px;
        @include font-size(22, 33);
        font-weight: 500;
        @include mobile576 {
            margin-bottom: 10px;
        }
    }

    &__text {
        h2 {
            @include font-size(22, 33);
            font-weight: 500;
        }

        p {
            @include font-size(17, 32);

            // &:not(:last-child) {
            //     margin-bottom: 20px;
            //     @include mobile576 {
            //         margin-bottom: 10px;
            //     }
            // }
        }
    }

    &__v-player-wrap {
        position: relative;
        max-width: 960px;
        width: 100%;
        // padding-top: 47%;
        aspect-ratio: 16/9;
        margin: 0 auto 100px;
        @include tablet1024 {
            // padding-top: 50px;
            margin-bottom: 60px;
        }
        @include tablet768 {
            max-width: calc(100% + 80px);
            width: calc(100% + 80px);
            margin-left: -40px;
        }
        @include mobile576 {
            max-width: calc(100% + 30px);
            width: calc(100% + 30px);
            margin-left: -15px;
            // padding-top: 40px;
            margin-bottom: 40px;
        }

        &._hide {
            .activity__v-player-controls {
                opacity: 0;
                visibility: hidden;
            }
        }

        // .vjs-control-bar {
        //     display: none;
        // }

        .video-js {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__v-player {
        width: 100%;
        height: 100%;
        // object-fit: cover;
    }

    &__v-player-controls {
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);
        background-image: url(../images/shadows.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        transition: all .3s linear;
        @include tablet1024 {
            top: 50px;
            height: calc(100% - 50px);
        }
        @include mobile576 {
            top: 40px;
            height: calc(100% - 40px);
        }

        .play-pause {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            img {
                @include mobile576 {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .progress-wrap {
            @include flex-column;
            position: absolute;
            bottom: 40px;
            left: 40px;
            width: calc(100% - 80px);
            @include mobile576 {
                width: calc(100% - 40px);
                left: 20px;
            }
        }

        .duration {
            display: inline-block;
            margin-left: auto;
            margin-bottom: 12px;
            padding: 4px 12px;
            @include font-size(14, 24);
            font-weight: 500;
            color: $white;
            background-color: $black;
            @include mobile576 {
                @include font-size(9, 15);
                padding: 4px 9px;
            }
        }

        .progressbar {
            width: 100%;
            height: 2.5px;
            background-color: $white;
            color: $red-400;

            &::-moz-progress-bar {
                background-color: $white;
                color: $red-400;
            }

            &::-webkit-progress-bar {
                background-color: $white;
            }

            &::-webkit-progress-value {
                transition: width 1s ease;
                background-color: $red-400;
            }
        }
    }

    &__content {
        padding-bottom: 90px;
        @include tablet768 {
            padding-bottom: 60px;
        }

        .activity__text {
            max-width: 790px;
            margin-left: auto;
            margin-right: auto;
            @include mobile576 {
                padding: 0 20px;
            }
            @include mobile375 {
                padding: 0;
            }
        }
    }

    &__img {
        @include flex-center-vert;
        max-width: 960px;
        width: 100%;
        min-height: 540px;
        margin: 60px auto;
        @include font-size(22, 33);
        font-weight: 500;
        color: $white;
        background-repeat: no-repeat;
        background-position: center right;
        @include tablet768 {
            max-width: calc(100% + 80px);
            width: calc(100% + 80px);
            margin: 50px 0 50px -40px;
            min-height: 432px;
            font-size: 18px;
            line-height: 29px;
        }
        @include mobile576 {
            max-width: calc(100% + 30px);
            width: calc(100% + 30px);
            margin: 40px 0 40px -15px;
            min-height: 233px;
            font-size: 13px;
            line-height: 19px;
        }

        &._ai-1 {
            background-image: url(../images/about/activity-img.png);
            @include mobile576 {
                background-image: url(../images/about/activity-img-mob.png);
            }
        }

        &._ai-2 {
            background-image: url(../images/about/activity-img-2.png);
            @include mobile576 {
                background-image: url(../images/about/activity-img-mob-2.png);
            }
        }

        & > div {
            max-width: 536px;
            padding-left: 87px;
            @include tablet768 {
                max-width: 418px;
                padding-left: 80px;
            }
            @include mobile576 {
                max-width: 246px;
                padding-left: 35px;
            }
        }
    }

    &__logo-wrap {
        @include flex-center-vert;
        max-width: 960px;
        width: 100%;
        margin: 40px auto 50px;
        padding: 50px 47px;
        padding-left: 89px;
        border: 1px solid $gray-600;
        @include tablet768 {
            max-width: 100%;
            padding: 50px 40px;
            width: 100%;
        }
        @include mobile576 {
            flex-direction: column;
            align-items: flex-start;
            padding: 25px 30px;
        }

        img {
            width: 112.03px;
            height: 100px;
            object-fit: contain;
            margin-right: 68px;

            @include tablet768 {
                width: 112.03px;
                height: 100px;
            }
            @include mobile576 {
                width: 84.02px;
                height: 74.78px;
                margin-bottom: 30px;
                margin-right: 0;
            }
        }

        .text {
            max-width: 557px;
            @include font-size(15, 24);
            font-weight: 500;
            text-transform: uppercase;
            @include tablet768 {
                max-width: 388px;
            }

            a {
                color: #E30613;
                transition: color $transition;

                @include desktop-hover {
                    color: #B7061D
                }
            }
        }
    }
}