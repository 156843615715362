.history {
    max-width: 716px;
    width: 100%;
    margin: 0 auto;
    padding-top: 60px;
    @include tablet768 {
        max-width: 572px;
    }
    @include mobile576 {
        padding: 40px 20px 0;
    }
    @include mobile375 {
        padding: 40px 0 0;
    }

    &__title {
        margin-bottom: 25px;
        @include font-size(22, 33);
        @include mobile576 {
            margin-bottom: 25px;
        }
    }

    &__text {
        margin-bottom: 40px;
        @include font-size(20, 34);
    }

    &__tree {
        max-width: 716px;
        width: 100%;
        margin: 0 auto 100px;
        @include tablet768 {
            max-width: calc(100% + 28px);
            width: calc(100% + 28px);
            margin-left: -14px;
            margin-bottom: 60px;
        }
        @include mobile576 {
            max-width: 100%;
            margin-left: 0;
            margin-bottom: 40px;
            padding: 0 20px;
        }
        @include mobile375 {
            padding: 0;
        }

        .tree {
            &__item {
                display: flex;
                width: calc(50% + 57px);
                min-height: 200px;
                @include mobile576 {
                    width: 100%;
                }

                &._right {
                    margin-left: auto;
                }

                &._left {
                    .tree__year-wrap {
                        order: 1;
                        // margin-right: 0;
                        // margin-left: auto;
                        @include mobile576 {
                            order: 0;
                            // margin-left: 0;
                            // margin-right: 12px;
                        }
                    }

                    .tree__text {
                        order: 0;
                        @include mobile576 {
                            order: 1;
                        }
                    }
                }

                &:last-child {
                    min-height: auto;

                    .tree__year-line {
                        display: none;
                    }
                }
            }

            &__year-wrap {
                @include flex-column;
                align-items: center;
                margin-right: 28px;
                margin-left: 28px;
                @include mobile576 {
                    margin-right: 12px;
                    margin-left: 0;
                }
            }

            &__year {
                @include flex-center;
                width: 58px;
                min-width: 58px;
                height: 58px;
                min-height: 58px;
                background-color: $red-600;
                border-radius: 50%;
                @include font-size(16, 22);
                font-weight: 700;
                color: $white;
            }

            &__year-line {
                height: 100%;
                width: 1px;
                background-color: $red-600;
            }

            &__text {
                flex: 1 0 1px;
                margin-top: 12px;
                @include font-size(17, 32);
            }
        }
    }
}