%clear_list {
  list-style: none;
  margin: 0;
  padding: 0;
}
%container {
  max-width: 1152px;
	width: 100%;
	margin: 0 auto;

  @include response(1200) {
    max-width: calc(100% - 40px);
  }
	@include tablet1024 {
		max-width: calc(100% - 80px);
	}

	@include mobile576 {
		max-width: calc(100% - 30px);
	}

}

%textContainer {
  max-width: 716px;
  width: 100%;
  margin: 0 auto;

  @include response(1023) {
    max-width: 576px;
  }

  @include response(767) {
    width: 100%;
    max-width: 100%;
    padding: 0 30px;
  }
}
%btn {
  display: inline-block;
  padding: 5px 25px;
  @include font-size(16, 34);
  font-weight: 500;
  color: $white;
  min-width: 180px;
  background-color: $red-400;
  text-align: center;
  transition: all .3s ease-in-out;

  &:hover {
      background-color: $red-800;
  }

    &._disabled {
        pointer-events: none;
        background: #F9F9FA;
        border: 1px solid #727880;
        color: #D9D9D9;
    // cursor: default;
        cursor: not-allowed;
    // pointer-events: none;
    }
}

%more {
  @include flex(row, nowrap, flex-start, center);
  position: relative;
  margin-top: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  text-align: left;
  color: #314c6e;

  transition: all $transition;

  &::before {
    content: '/\00a0';
    display: block;
    color: $red-100;
  }

  @include desktop-hover {
    color: $red-100;
  }
}
