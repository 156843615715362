.card-news {
  @include flex(column, nowrap, flex-start, flex-start);
  margin-bottom: 45px;

  &__cover {
    width: 100%;
    height: 200px;
    object-fit: cover;

    @include response(1023) {
      height: 253px;
    }
  }

  &__section {
    @include flex(column, nowrap, flex-start, flex-start);

    width: 100%;
    padding-top: 15px;
    flex: 1 1 auto;
  }

  &__date {
    font-size: 14px;
    line-height: 24px;
    color: $dark-gray-600;
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    color: $dark-gray-600;
    transition: color $transition;

  }

  &__link {
    @extend %more;
  }

  @include response(767) {
    margin-bottom: 35px;
  }

  @include desktop-hover {
    .card-news__link,
    .card-news__title {
      color: $red-100;
    }
  }
}