.notfound {
  width: 100%;


  &__wrapper {
    @include flex(row, nowrap, flex-start, flex-start);
    padding: 60px 0 100px;
    max-width: 980px;
	  width: 100%;
    margin: 0 auto;

    @include response(1023) {
      padding: 50px 60px 50px;
    }

    @include response(767) {
      @include flex(column, nowrap, flex-start, flex-start);
      padding: 30px 60px 40px;
    }

    @include response(413) {
      padding: 30px 30px 40px;
    }
  }
  &__icon {
    width: 269.07px;
    height: 351px;
    margin-right: 40px;

    @include response(1023) {
      width: 220px;
      height: 287px;
      margin-right: 40px;
    }

    @include response(767) {
      margin-right: 0;
      margin-bottom: 40px;
      width: 180px;
      height: 235px;
    }
  }

  &__section {
    padding-top: 55px;
    p {
      font-size: 20px;
      line-height: 34px;
      color: #314c6e;
      margin-bottom: 35px;

      @include response(767) {
        margin-bottom: 20px;
      }
    }

    @include response(1023) {
      padding-top: 15px;
    }

    @include response(767) {
      padding-top: 0;
      width: 100%;
    }
  }

  &__link {
    @extend %btn;
  }
}
