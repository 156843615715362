.docs {
  &__list {
      max-width: 960px;
      margin: 40px auto 0;
      @include tablet768 {
          margin-top: 30px;
          padding: 0 20px;
      }
      
      @include response(767) {
        padding: 0;
      }

      &:first-of-type {
          margin-bottom: 70px;
          @include tablet768 {
              margin-bottom: 50px;
          }
      }
  }

  &__item {
      margin-bottom: 20px;
      border: 1px solid $gray-600;
  }

  &__link {
      @include flex-center-vert;
      justify-content: space-between;
      padding: 25px 95px 25px 90px;
      transition: all .3s ease-in-out;
      @include tablet768 {
          padding: 25px 55px 25px 40px;
      }
      @include mobile576 {
          padding: 25px 50px 25px 20px;
      }

      &:hover {
          color: $red-500;
      }
  }

  &__text {
      @include font-size(17, 32);
      font-weight: 500;
  }

  &__icon {
      width: 34px;
      height: 46px;
      margin-left: auto;
      @include mobile576 {
          margin-left: 15px;
      }

      &--pdf {
          color: #FF8A80;
          fill: $red-500;
      }

      &--xls {
          color: #9FE3B9;
          fill: $green-200;
      }
  }
}