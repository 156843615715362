.activity__v-player-wrap {
	.popup-video {

		.video-js {
			z-index: 20;
			@include response(1025) {
				pointer-events: none;
			}
		}

		.vjs-big-play-button {
			display: block !important;
		}

		&.open {

			.vjs-remaining-time.vjs-time-control.vjs-control {
				font-size: 1em;
			}
			.vjs-big-play-button {
				display: none !important;
			}

			.video-js {
				left: auto;
				top: auto;
				// pointer-events: all;
				width: 70%;
				height: auto;
				aspect-ratio: 16/9;

				@include response(1025) {
					pointer-events: all;
					width: 85%;
				}
			}
		}
	}
}

.popup-video {
	@include flex(row, wrap, center, center);
	transition: all $transition;
	position: relative;
	width: 100%;
	height: 100%;

	&__overlay {
		display: none;
		cursor: pointer;
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}


	&__close {
		width: 20px;
		height: 20px;
		position: absolute;
		z-index: 20;
		top: 20px;
		right: 20px;
		display: none;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&.open {
		@include flex(row, wrap, center, center);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;

		.popup-video__overlay {
			display: block;
			pointer-events: all;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.popup-video__close {
			display: block;
		}
	}
}