.header {
	position: relative;
	z-index: 2;
	background-color: $white;
	transition: background-color 0.25s linear;

	&__search--container {
		position: relative;
		display: block;
		width: 100%;
		z-index: 1;
	}

	&__top {
			@include flex-center-vert;
			// padding: 24px 0 15px;
	}

    &__inner {
        @include flex(row, nowrap, flex-end, center);
        margin-left: auto;
    }

    &__link--main {
        @include font-size(14, 20);
        position: relative;
        right: -48px;
        z-index: 10;
        @include flex-center-vert;
        position: relative;
        transition: all 0.25s linear;

        .cls-1 {
            stroke: #314C6E;
            transition: all 0.25s linear;

        }

        span {

            position: relative;
            z-index: 2;
        }

        svg {

            width: 15px;
            height: 15px;
            margin-right: 15px;
            color: #0000ff00;
        }

        @include response(1023) {
            right: 0;
        }

        @include response(767) {
            right: -45px;

            span {
                display: none;
                margin-right: 0;
            }
        }

        @include desktop-hover {
            color: #E40A13;

            .cls-1 {
                stroke: #E40A13;
            }
        }
    }

	&__search {
			@include flex-center-vert;
			margin-left: auto;
			padding: 10px 0;
			position: relative;
			@include font-size(14, 20);
			cursor: pointer;
			height: 70px;
			padding-right: 58px;
			padding-left: 67px;
			right: -48px;

			svg,
			span {
				position: relative;
				z-index: 2;
				transition: all 0.25s linear;
			}
			.search-svg {
					width: 15px;
					height: 15px;
					margin-left: 15px;
					stroke: $dark-gray-300;
			}

			.search-svg--close {
				display: none;
				width: 15px;
				height: 15px;
				margin-left: 15px;
				stroke: $dark-gray-300;
		}

			&:hover {
					color: $red-500;

					.search-svg {
							stroke: $red-500;
					}
			}

		&.open {
			transition: all .25s ease-in-out;
			color: #fff;
			background: #e40a13;
			.search-svg {
				display: none;
			}
			.search-svg--close {
				display: block;
			}

			@include desktop-hover {
				color: #fff;
				background-color: #314C6E;
			}
		}

		@include response(1023) {
			padding-right: 24px;
			padding-left: 28px;
			right: 0;
		}

		@include response(767) {
			height: 60px;
			right: -15px;
		}
	}

	&__logo {
			@include mobile576 {
					width: 97px;
					margin-right: 25px;
			}
	}

	&__home {
			display: none;

			@include mobile576 {
					@include flex-center-vert
			}

			&-slash {
					width: 15px;
					height: 15px;
					margin-right: 15px;
					stroke: $dark-gray-600;
			}

			&-link {
					.home-svg {
							width: 17px;
							height: 15px;
							fill: $red-500;
							color: transparent;
					}
			}
	}

	&__bottom {
			position: relative;
			background-color: $red-400;

			.container {

				@include response(1023) {
					max-width: 100%;
					padding-left: 50px;
				}
				@include response(767) {
					max-width: 100%;
					padding-left: 0;
				}
			}
	}

	.navbar {
			color: $white;
			@include mobile576 {
					width: 100%;
					margin-left: 0;
			}

			&__menu {
					@include flex-center-vert;
			}

			&__item {
					@include mobile576 {
							width: calc(100% / 4);
					}
					@include mobile375 {
							width: auto;
							flex: 1 1 auto;
					}

					&:first-child {
							.navbar__link {
									padding-left: 0;
							}

							@include mobile576 {
									display: none;
							}
					}

					&:nth-child(2) {
							.navbar__link {
									background-color: $red-100;
							}
					}

					&:nth-child(3) {
							@include mobile576 {
									width: 122px;
							}
							@include mobile375 {
									width: auto;
							}

							.navbar__link {
									background-color: $red-500;
							}
					}

					&:nth-child(4) {
							.navbar__link {
									background-color: #d01520;
							}
					}

					&:nth-child(5) {
							.navbar__link {
									background-color: $red-800;
							}
					}

					&:nth-child(6) {
						.navbar__link {
								background-color: $red-700;
						}
				}

					&:not(._unHover) {
							&:hover {
									&:not(:first-child) {
											.navbar__link {
													background-color: $dark-gray-600;
											}
									}

									.navbar__link {
											.home-svg {
													color: #fff;
											}
									}

									& > .navbar__submenu-container {
											transition: clip-path 0.25s ease-in-out;
											clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
											// transform: rotateX(0deg);
											visibility: visible;
											opacity: 1;
									}
							}
					}

					&._show {
							& > .navbar__submenu-container {
									// transform: rotateX(0);
									// transition: .3s linear;
									// visibility: visible;
									// opacity: 1;
							}
					}
			}

			&__item {
				flex: 1 1 auto;
			}
			&__link {
					@include flex(row, wrap, center, center);
					padding: 15px 32px;
					white-space: nowrap;
					@include font-size(16, 21); // font-size(размер шрифта, line-height, letter-spacing)
					color: inherit;
					cursor: pointer;

					@include response(1023) {
						padding: 15px 20px;
					}
					@include mobile576 {
							padding: 10px 17px;
							@include font-size(13, 18);
							white-space: nowrap;
							text-align: center;
					}
					@include mobile375 {
							@include font-size(9, 18);
							padding: 10px 5px;
					}
					.home-svg {
							width: 17px;
							height: 15px;
							fill: $white;
							color: transparent;
							transition: all .3s ease-in-out;
					}
			}

			&__page-info {
					max-width: 336px;
					@include tablet992 {
							display: flex;
							align-items: flex-start;
							gap: 0 25px;
							max-width: 100%;
							margin-bottom: 55px;
					}
					@include mobile576 {
							margin-bottom: 25px;
					}

					.info {
							&__text-wrapper {
									margin-top: 24px;
									@include tablet992 {
											margin-top: 0;
									}
							}

							&__img {
									width: 100%;
									font-size: 0;
									@include tablet992 {
											width: 278px;
									}
									@include mobile576 {
											display: none;
									}
							}

							&__title {
									margin-bottom: 10px;
									@include font-size(24, 34);
									color: $light-blue-100;
									@include mobile576 {
											margin-bottom: 0;
									}
							}

							&__text {
									@include font-size(15, 24);
									font-weight: 300;
									@include mobile576 {
											display: none;
									}
							}
					}
			}

			&__submenu-container {
					position: absolute;
					z-index: 5;
					left: 0;
					top: 50px;
					width: 100%;
					background-color: $dark-gray-600;
					overflow: hidden;
					visibility: hidden;
					opacity: 0;
					clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
					// transform: rotateX(-90deg);
					@include mobile576 {
							top: 37px;
					}
			}

			&__submenu-wrapper {
					display: flex;
					gap: 0 40px;
					padding: 40px 0 118px;

					@include tablet992 {
							flex-direction: column;
							align-items: flex-start;
							padding-bottom: 35px;
					}
					@include mobile576 {
							padding: 0 15px;
							padding-top: 20px;
							padding-bottom: 50px;
					}
			}

			&__submenu {
					position: relative;
					min-width: 292px;
					@include mobile576 {
							width: 100%;
					}
			}

			&__sub-link {
				display: block;
				cursor: pointer;
				padding: 15px;
				transition: all $transition;
				&:hover {
					font-weight: 500;
				}
			}
			&__sub-item {
					transition: all $transition;
					// cursor: pointer;


					&[data-open-menu="true"] {

						& > div {
							cursor: pointer;
							position: relative;
							&:before {
								content: '';
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								right: 15px;
								width: 6px;
								height: 12px;
								background-image: url(../images/chevron-right-red.svg);
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
								transition: all .3s linear;
								opacity: 1;
							}
						}
					}


					&:hover {
						> div {
							background-color: $dark-gray-500;
						}
						&:before {
							opacity: 1;
						}

						.navbar__sub-link {
								font-weight: 500;
						}
						// + .navbar__nested-menu {
						// 	transform: rotateX(0);
						// 	visibility: visible;
						// 	opacity: 1;
						// 	// height: auto;
						// 	max-height: 850px;
						// }
					}

					&[data-open-menu="false"] {
							&:before {
									display: none;
							}
					}
					& > div {
							position: relative;
							// padding: 15px;
							font-weight: 300;
							@include font-size(15, 24);
							border-bottom: 1px solid $dark-gray-400;
							transition: all .3s linear;
					}

					&:first-child {
							& > div {
									border-top: 1px solid $dark-gray-400;
							}
					}

					&:not(._unHover) {
							&:hover {
									// & > div {
									// 		background-color: $dark-gray-500;

									// 		&:before {
									// 				opacity: 1;
									// 		}
									// }

									// .navbar__sub-link {
									// 		font-weight: 500;
									// }

									& > .navbar__nested-menu {
										transform: rotateX(0);
										visibility: visible;
										opacity: 1;
										max-height: 850px;
									    // min-height: 850px;
									}
							}
					}

					&._show {
							& > div {
									background-color: $dark-gray-500;

									&:before {
											transform: translateY(-50%) rotate(90deg);
									}
							}

							.navbar__sub-link {
									font-weight: 500;
							}

							& > .navbar__nested-menu {
									transform: rotateX(0);
									visibility: visible;
									opacity: 1;

									@include mobile576 {
											max-height: 450px;
											transition: all $transition;
									}
							}
					}
			}

			&__nested {
					&-menu {
							position: absolute;
							left: 292px;
							top: 0;
							width: 300%;
							height: calc(100% + 118px);
							padding-left: 12px;
							background-color: $dark-gray-500;
							overflow: hidden;
							visibility: hidden;
							opacity: 0;
							transform-origin: 0 0;
							transform: rotateX(-90deg);
							transition: .3s linear;

							@include tablet992 {
								flex-direction: column;
								align-items: flex-start;
								height: calc(100% + 35px);
						}

							@include mobile576 {
									position: static;
									width: 100%;
									max-height: 0;
									padding-left: 40px;
									padding-right: 40px;
									background-color: transparent;
									visibility: visible;
									opacity: 1;
									transform: rotateX(0);
									transition: max-height .25s linear;
							}
					}

					&-item {
							max-width: 292px;
							font-weight: 300;
							@include font-size(15, 24);
							border-bottom: 1px solid $dark-gray-400;
							@include mobile576 {
									max-width: 100%;
							}

							&:first-child {
									border-top: 1px solid $dark-gray-400;
									@include mobile576 {
											border-top: 0;
									}
							}

							&._active {
									.navbar__nested-link {
											color: $light-blue-300;
									}
							}

							@include mobile576 {
								padding: 12px;
							}

					}

					&-link {
							transition: all .3s ease-in-out;
							display: block;
							padding: 15px;

							@include desktop-hover {
								color: $light-blue-300;
							}
					}
			}
	}
}
