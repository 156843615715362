.cookie {
    width: 100%;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    position: fixed;
	z-index: 999;
	bottom: 0;
	left: 0;
	padding: 40px;
	text-align: left;
    background-color: #F9F9F9;
    color: #314C6E;

    &.is-hidden {
        display: none;
    }

    h2 {
        margin-bottom: 15px;
        @include font-size(24, 28);
        font-weight: 700;

        br {
            display: none;
        }

        @include response(1023) {
            @include font-size(24, 28);
            margin-bottom: 25px;
        }

        @include response(767) {
            @include font-size(24, 31);
            margin-bottom: 18px;

            br {
                display: block;
            }
        }

    }

    p {
        @include font-size(14, 20);

        margin-bottom: 25px;

        @include response(1023) {
            @include font-size(16, 22);
            margin-bottom: 40px;
        }

        @include response(767) {
            @include font-size(12, 18);
            margin-bottom: 18px;
        }
    }

    &__navigation {
        @include flex(row, wrap, flex-start, center);
        @include response(767) {
            @include flex(row, wrap, space-between, center);
        }
    }

    &__link,
    &__btn {
        @include flex(row, center, center, center);
        text-align: center;
        width: 200px;
        height: 48px;
        padding: 5px 25px;
        font-size: 16px;
        font-weight: 500;
        transition: all .3s ease-in-out;
    }

    &__btn {
        background-color: $red-400;
        color: $white;
        margin-right: 25px;

       @include desktop-hover {
            background-color: $red-800;
       }

        @include response(767) {
            order: 2;
            width: 78px;
            margin-right: 0;
        }
    }

    &__link {
        color: $red-400;
        border: 1px solid $red-400;

        @include desktop-hover {
            background-color: $red-400;
            color: $white;
        }

        @include response(767) {
            order: 1;
            width: 178px;
        }
    }


    @include response(767) {
        padding: 30px 20px;
    }
}
