#pagination {
    width: 100%;
    // display: none;

    ul {
        @include flex(row, nowrap, flex-start, center);

        @include response(767) {
            @include flex(row, nowrap, space-between, center);
        }
    }

    li {
        margin-right: 35px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #727880;

        a {
            transition: color 0.3s ease-in-out;
            @include desktop-hover {
                color: #314C6E;
            }
        }

        &.active {
            font-weight: 500;
            color: #314C6E;
        }

        @include response(767) {
            margin: 0 10px;
        }

        .prev {
            @include flex(row, nowrap, space-between, center);

            &::before {
                content: '';
                background-image: url("../images/icon/arrow.svg");
                transform: scale(-1, 1);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                display: block;
                width: 10px;
                margin-right: 10px;
                height: 10px;
            }
        }
        .next {
            @include flex(row, nowrap, space-between, center);

            &::after {
                content: '';
                background-image: url("../images/icon/arrow.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                display: block;
                width: 10px;
                margin-left: 10px;
                height: 10px;
            }
        }
    }

    // .current.prev {
    //     display: none;
    //     opacity: 0;
    // }

    @include response(767) {
        margin-left: -10px;
        width: calc(100% + 20px);
    }
}
