.ff {
    &__title {
        margin-bottom: 15px;
        @include font-size(22, 33);
    }

    &__text {
        margin-bottom: 40px;
        @include font-size(17, 32);
        @include mobile576 {
            margin-bottom: 15px;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 312px);
        grid-gap: 50px;
        justify-content: center;
        padding: 60px 0;
        @include tablet1024 {
            grid-template-columns: 1fr;
            grid-gap: 40px;
            padding: 50px 0;
        }
        @include mobile576 {
            grid-gap: 30px;
            padding: 40px 0;
        }

        &-wrap {
            background-color: $white-500;
        }
    }

    &__research {
        display: flex;
        padding: 60px 0 60px 54px;
        @include tablet1024 {
            padding: 50px 0;
        }
        @include mobile576 {
            flex-direction: column;
            padding: 30px 0 30px 20px;
        }
        @include mobile375 {
            padding-left: 0;
        }

        .research {
            &__img {
                position: relative;
                z-index: 1;
                max-width: 312px;
                width: 100%;
                font-size: 0;
                @include tablet768 {
                    max-width: 272px;
                }
                @include mobile576 {
                    max-width: 247px;
                }

                img {
                    width: 100%;
                }
            }

            &__text-wrap {
                max-width: 482px;
                margin-top: 64px;
                margin-left: 60px;
                @include tablet768 {
                    max-width: 406px;
                    margin-left: 18px;
                    margin-top: 26px;
                }
                @include mobile576 {
                    max-width: 100%;
                    margin-top: 30px;
                    margin-left: 0;
                }
            }

            &__digit {
                position: relative;
                @include mobile576 {
                    padding-right: 31px;
                    text-align: right;
                }

                .text {
                    @include font-size(36, 32);
                    color: $red-500;

                    .number {
                        @include font-size(54, 32);
                    }
                }

                svg {
                    position: absolute;
                    left: -415px;
                    top: 35px;
                    width: 606px;
                    height: 104px;
                    fill: $red-500;
                    stroke: $red-500;
                    @include tablet768 {
                        left: -400px;
                        width: 575px;
                        height: 85px;
                    }
                    @include mobile576 {
                        left: auto;
                        top: 40px;
                        right: 103px;
                    }
                }
            }
        }
    }

    &__therapeutic {
        max-width: 716px;
        width: 100%;
        margin: 0 auto;
        padding-top: 47px;
        @include tablet768 {
            padding-top: 39px;

            .ff__text {
                margin-bottom: 18px;
            }
        }
        @include mobile576 {
            padding: 104px 20px 0;

            .ff__text {
                margin-bottom: 10px;
            }
        }
        @include mobile375 {
            padding-left: 0;
            padding-right: 0;
        }

        .therapeutic {
            &__list-wrap {
                display: flex;
                @include mobile576 {
                    flex-direction: column;
                }
            }

            &__list {
                @include flex(row, wrap, flex-start, flex-start);
                width: 100%;
                // &:first-child {
                //     margin-right: 100px;
                //     @include mobile576 {
                //         margin-right: 0;

                //         .therapeutic__item {
                //             &:last-child {
                //                 margin-bottom: 20px;
                //             }
                //         }
                //     }
                // }
                @include response(767) {
                    @include flex(column, nowrap, flex-start, flex-start);
                }
            }

            &__li {
                width: 50%;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                @include response(767) {
                    width: 100%;
                }
            }
            &__item {
                @include flex(row, nowrap, flex-start, flex-start);
                @include font-size(17, 32);
                color: $red-500;
                display: inline-flex;
                transition: all .3s ease-in-out;
                cursor: pointer;

                svg {
                    position: relative;
                    top: 10px;
                    width: 9px;
                    height: 12px;
                    margin-right: 15px;
                    fill: $dark-gray-600;
                }

                &:hover {
                    color: $dark-gray-600;
                }
            }
        }
    }

    &__audit {
        max-width: 716px;
        width: 100%;
        margin: 0 auto;
        padding: 60px 0;
        @include tablet768 {
            .ff__text {
                margin-bottom: 20px;
            }
        }
        @include mobile576 {
            padding: 50px 20px 20px;
        }
        @include mobile375 {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.ff-card {
    position: relative;
    min-height: 460px;
    cursor: pointer;
    overflow: hidden;
    transition: all .4s linear;
    @include tablet1024 {
        min-height: 222px;
    }

    &._fade-in {
        box-shadow: 0 3px 20px rgba(0, 0, 0, 16%);

        .ff-card__main {
            transform: translateX(-100%);
        }

        .ff-card__second {
            transform: translateX(0);
        }
    }

    .button {
        @include flex-center;
        position: absolute;
        width: 38px;
        height: 38px;
        @include mobile576 {
            display: none;
        }

        svg {
            width: 14px;
            height: 7px;
        }
    }

    &__main {
        position: relative;
        @include flex-column;
        height: 100%;
        padding: 45px 25px 80px;
        background-color: $red-600;
        color: $white;
        transition: all .3s linear;
        @include tablet1024 {
            flex-direction: row;
            padding: 45px;
        }
        @include mobile576 {
            padding: 45px 25px;
            cursor: pointer;
        }

        .title {
            padding-right: 45px;
            @include font-size(32, 40);
            text-transform: uppercase;
            @include mobile576 {
                padding-right: 15px;
            }
            @include mobile375 {
                @include font-size(24, 32)
            }
        }

        .icon {
            pointer-events: none;
            margin-top: auto;
            @include tablet1024 {
                margin-left: auto;
                margin-right: 111px;
            }
            @include mobile576 {
                margin: auto 0;
            }

            object {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(244deg) brightness(102%) contrast(103%);
            }

            &._factory {
                svg {
                    width: 101px;
                    height: 109px;
                    stroke: $white;
                    @include mobile576 {
                        width: 90px;
                        height: 98px;
                    }
                }
            }

            &._worldwide {
                svg {
                    width: 110px;
                    height: 110px;
                    fill: $white;
                    @include mobile576 {
                        width: 97px;
                        height: 97px;
                    }
                }
            }

            &._team {
                img {
                    width: 120px;
                    height: 101px;
                    @include mobile576 {
                        width: 110px;
                        height: 92px;
                    }
                }
            }
        }

        .button {
            right: 25px;
            bottom: 25px;
            border: 1px solid $white;
            cursor: pointer;
            @include tablet1024 {
                right: 34px;
                bottom: 49px;
            }

            svg {
                stroke: $white;
            }
        }
    }

    &__second {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 52px 30px;
        background-color: $white;
        transform: translateX(100%);
        transition: all .3s linear;
        @include tablet1024 {
            display: flex;
            padding: 45px;
        }
        @include mobile576 {
            padding: 30px 25px;
            cursor: pointer;
        }
        @include mobile375 {
            padding: 15px;
        }

        .icon {
            pointer-events: none;
            object {
                filter: invert(21%) sepia(62%) saturate(7421%) hue-rotate(350deg) brightness(86%) contrast(109%); 
            }
            margin-top: auto;
            @include tablet1024 {
                order: 1;
                margin-left: auto;
                margin-right: 111px;
            }
            @include mobile576 {
                display: none;
            }

            &._factory {
                margin-bottom: 78px;
                @include tablet1024 {
                    margin-bottom: 0;
                }

                svg {
                    width: 101px;
                    height: 109px;
                    stroke: $red-500;
                }
            }

            &._worldwide {
                margin-bottom: 30px;
                @include tablet1024 {
                    margin-bottom: 0;
                }

                svg {
                    width: 110px;
                    height: 110px;
                    fill: $red-500;
                }
            }

            &._team {
                margin-bottom: 30px;
                @include tablet1024 {
                    margin-bottom: 0;
                }

                img {
                    width: 120px;
                    height: 101px;
                }
            }
        }

        .text-wrap {
            @include tablet1024 {
                max-width: 368px;
            }
            @include mobile576 {
                max-width: 100%;
            }
        }

        .title {
            margin-bottom: 10px;
            @include font-size(32, 40);
            color: $red-500;

            &._team {
                margin-bottom: 40px;
                @include tablet1024 {
                    margin-bottom: 30px;
                }
                @include mobile576 {
                    margin-bottom: 0;
                }
            }
        }

        .text {
            @include font-size(16, 26);

            &._staff {
                @include mobile576 {
                    margin-top: 47px;
                }
            }
        }

        .button {
            right: 15px;
            bottom: 15px;
            border: 1px solid $red-500;
            cursor: pointer;
            @include tablet1024 {
                right: 34px;
                bottom: 49px;
            }

            svg {
                transform: rotate(180deg);
                stroke: $red-500;
            }
        }
    }
}