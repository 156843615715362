.leadership {
    padding: 20px 0 90px;
    @include mobile576 {
        padding: 5px 0 50px;
    }

    &__title {
        margin-bottom: 15px;
        @include font-size(40, 25);
        font-weight: 300;
        color: $red-500;
        @include mobile576 {
            @include font-size(40, 44);
        }
    }

    &__desc {
        margin-bottom: 40px;
        @include font-size(17, 32);
        @include tablet768 {
            margin-bottom: 20px;
        }

    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 34px;
        @include tablet768 {
            grid-template-columns: repeat(2, 264px);
            grid-gap: 50px 30px;
        }
        @include mobile576 {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px 13px;
        }
    }

		&__item {
			@include desktop-hover {
				.leadership__position,
				.leadership__link {
					color: $red-500;
				}
			}
		}

    &__img {
        width: 100%;
        min-height: 276px;
        margin-bottom: 18px;
        background-repeat: no-repeat;
        background-position: 50% 15%;
        background-size: cover;
        @include mobile576 {
            min-height: 196px;
            margin-bottom: 20px;
            background-position-y: 13%;
        }
    }

    &__name {
        margin-bottom: 10px;
        @include font-size(18, 26);
        font-weight: 700;
        color: $red-500;
        text-transform: uppercase;
    }

    &__position {
        margin-bottom: 10px;
				transition: all .3s ease-in-out;
        @include font-size(16, 23);
    }

    &__link {
        @include flex(row, nowrap, flex-start, center);
        @include font-size(16, 34);
        font-weight: 500;
        transition: all .3s ease-in-out;

        svg {
            margin-right: 5px;
            width: 9px;
            height: 12px;
            fill: $red-200;
        }

        &:hover {
            color: $red-500;
        }
    }
}

.leadership-inner {
    display: flex;
    align-items: flex-start;
    padding: 55px 58px 90px;
    @include tablet768 {
        padding: 25px 0 90px;
    }
    @include mobile576 {
        padding: 10px 20px 30px;
        flex-direction: column;
    }
    @include mobile375 {
        padding: 10px 0 30px;
    }

    &--russia {
        padding: 55px 58px 45px;
        @include tablet1024 {
            padding-bottom: 50px;
        }
        @include tablet768 {
            padding: 25px 0 90px;
        }
        @include mobile576 {
            padding: 10px 20px 50px;
        }
        @include mobile375 {
            padding: 10px 0 30px;
        }
    }

    &__img {
        max-width: 312px;
        min-width: 312px;
        width: 100%;
        min-height: 460px;
        margin-right: 61px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include tablet768 {
            max-width: 246px;
            min-width: 246px;
            min-height: 362px;
            margin-right: 20px;
        }
        @include mobile576 {
            max-width: 180px;
            min-width: 180px;
            min-height: 264px;
            margin-right: 0;
        }
    }

    &__text-wrap {
        padding-top: 34px;
        @include mobile576 {
            padding-top: 10px;
        }
    }

    &__name {
        margin-bottom: 20px;
        @include font-size(38, 25);
        font-weight: 300;
        color: $red-500;
        @include tablet1024 {
            @include font-size(38, 48);
        }
        @include tablet768 {
            margin-bottom: 15px;
        }
        @include mobile576 {
            margin-bottom: 10px;
        }
    }

    &__position {
        margin-bottom: 20px;
        @include font-size(20, 34);
        font-weight: 500;
    }

    &__text {
        max-width: 482px;
        @include font-size(17, 32);
    }

    &__grid-wrap {
        padding: 50px 0;
        background-color: $white-500;
        @include tablet768 {
            padding-bottom: 60px;
        }
        @include mobile576 {
            padding: 30px 0 40px;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 260px);
        grid-gap: 30px;
        justify-content: center;
        @include tablet768 {
            grid-template-columns: repeat(2, 260px);
            grid-gap: 50px 30px;
            justify-content: start;
        }
        @include mobile576 {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px 13px;
        }
    }

    &__appeal-wrap {
        position: relative;
        padding-top: 150px;
        background-color: $white-500;
        @include tablet1024 {
            padding-top: 80px;
        }
        @include tablet768 {
            padding-top: 60px;
        }
        @include mobile576 {
            padding-top: 50px;
        }

        .appeal__gradient {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 179px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, .75) 50%,
                    rgba(255, 255, 255, .97) 100%
            );
            transition: all .3s linear;
        }

        &._show {
            .appeal {
                &__gradient {
                    opacity: 0;
                    visibility: hidden;
                }

                &__content {
                    //max-height: var(--total-height);
                    max-height: none;
                }

                &__expand {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}

.appeal {
    max-width: 716px;
    width: 100%;
    margin: 0 auto;
    @include tablet768 {
        padding: 0 40px;
    }
    @include mobile576 {
        padding: 0 20px;
    }
    @include mobile375 {
        padding: 0;
    }

    &__head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
        @include mobile576 {
            position: relative;
            justify-content: flex-start;
            padding-bottom: 140px;
        }
    }

    &__title-wrap {
        max-width: 485px;
    }

    &__quote {
        font-family: 'Impact', sans-serif;
        @include font-size(94, 94);
        color: $red-500;
        @include mobile576 {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__title {
        margin-top: -42px;
        @include font-size(24, 34);
        font-weight: 500;
        @include mobile576 {
            position: absolute;
            left: 0;
            bottom: 20px;
            margin-top: 0;
        }
    }

    &__img {
        width: 134px;
        height: 134px;
        margin-top: 10px;
        border-radius: 50%;
        @include mobile576 {
            margin: 0 auto;
        }
    }

    &__content {
        position: relative;
        max-height: 542px;
        overflow: hidden;
        transition: all .3s linear;
    }

    &__interview-block {
        margin-bottom: 60px;
        @include mobile576 {
            margin-bottom: 30px;
        }

        &:last-of-type {
            margin-bottom: 26px;
        }
    }

    &__question {
        margin-bottom: 30px;
        @include font-size(17, 32);
        font-weight: 500;
        @include mobile576 {
            margin-bottom: 15px;
        }
    }

    &__answer {
        @include font-size(17, 32);
    }

    &__collapse {
        @include flex(row, wrap, flex-start, center);
        margin-bottom: 100px;
        color: #10384f;
        @include font-size(16, 34);
        font-weight: 500;
        cursor: pointer;
        transition: all .3s ease-in-out;
        @include mobile576 {
            margin-bottom: 40px;
        }

        svg {
            width: 15px;
            height: 14px;
            margin-left: 5px;
            stroke: $dark-gray-800;
            transform: rotate(-90deg);
            transition: all .3s ease-in-out;
        }

        &:hover {
            color: $red-500;

            svg {
                stroke: $red-500;
            }
        }

        @include response(1023) {
            margin-bottom: 60px;
        }

        @include response(767) {
            margin-bottom: 40px;
        }
    }

    &__expand {
        @include flex(row, wrap, flex-start, center);
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: 145px;
        @include font-size(16, 34);
        font-weight: 500;
        cursor: pointer;
        color: $red-400;
        transition: all .3s linear;

        @include response(1023) {
            bottom: 70px;
        }

        @include response(767) {
            bottom: 40px;
        }

        svg {
            width: 15px;
            height: 14px;
            margin-left: 5px;
            margin-bottom: -1px;
            stroke: $red-400;
            transform: rotate(90deg);
            transition: all .3s linear;
        }

        &:hover {
            color: $dark-gray-600;

            svg {
                stroke: $dark-gray-600;
            }
        }
    }
}