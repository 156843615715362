.therapeutic-inner {
  width: 100%;

  &__wrapper {
    @extend %container;
  }

  &__text {
    @extend %textContainer;
    @include font-size(17, 32);
    padding: 60px 0;

    @include response(767) {
      padding: 0 20px;
      margin-bottom: 65px;
    }


    p {
      @include font-size(17, 32);

        &:not(:last-child) {
          margin-bottom: 25px;
        }
    }

    h3,
    h2 {
      margin-bottom: 25px;
      @include font-size(22, 33);
      font-weight: 500;
      @include mobile576 {
        margin-bottom: 15px;
      }
    }
  }


  .activity__v-player-wrap {
    max-width: 279px;
    margin-left: 0;

    @include response(1023) {
      max-width: 216px;
      margin-bottom: 0;
    }
  }

  .activity__v-player-controls .progress-wrap {
    width: 100%;
    padding: 0 15px;
    left: 0;
    bottom: 15px;
  }

  .play-pause {
    width: 35px;
    height: 35px;

    @include response(767) {
      width: 30px;
      height: 30px;

      img {
        object-fit: contain;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}