.home {
	width: 100%;
	overflow: hidden;

	.navbar__item--home {
		display: none;
	}

	.header__link {
		pointer-events: none;
	}

	&__list {
		@include flex(column, wrap, flex-start, center);
		background: #fcfcfc;
		position: relative;

		@include response(1023) {
			padding-bottom: 30px;
		}
	}

	&__wrapper {
		padding: 57px 0 40px;

		@include response(1023) {
			padding: 47px 0 40px;
		}

		&--tabs {
			@include response(767) {
				padding-bottom: 0;
			}
		}
	}

	&__container {
		@extend %container;
		background: #fff;

		@include response(767) {
			max-width: calc(100% - 60px);
		}
	}

	&__subtitle {
		margin-bottom: 35px;
		font-size: 28px;
		line-height: 34px;
		text-align: left;
		color: $red-400;

		@include response(767) {
			margin-bottom: 25px;
		}
	}

	&__section {

		@include flex(row, wrap, space-between, stranch);
		width: 100%;

		&--card-news {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap: 2.5vw;
			border-bottom: 1px solid #e5e5e5;
			margin-bottom: 40px;
		}

		@include response(1023) {
			@include flex(column, nowrap, flex-start, center);

			&--card-news {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 4vw;
			}
		}

		@include response(767) {

			&--card-news {
				position: relative;
				grid-template-columns: 1fr;
				grid-gap: 0;
				border: none;
				&::before {
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translate3d(-50%, 0, 0);
					width: 100vw;
					height: 1px;
					content: "";
					display: block;
					background-color: #e5e5e5;
				}
			}
		}
	}

	&__more {
		@extend %btn;

		@include response(767) {
			margin: 0 auto;
			display: flex;
		}
	}

}
