* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
	scroll-behavior: smooth;
    font-size: 17px;

    @include response(767) {
        font-size: 10px;
    }

    @include response(414) {
        font-size: calc(100vw / 414 * 10);
    }
}

body {
	font-family: "Roboto", sans-serif;
	// font-size: 17px;
	font-weight: 400;
	color: $dark-gray-600;
	overflow-x: hidden;

}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

h1, h2, h3, h4, h5, h6, p {
	padding: 0;
	margin: 0;
}

button {
	background-color: transparent;
	border: none;

}
button, input, optgroup, select, textarea {
    font-family: "Roboto", sans-serif;
}

.container {
	@extend %container;
}

.logo {
	width: 112px;
}

main {
	flex: 1;
}

p {
	margin: 0;
}

a,
button {
	outline: none;
	transition: $duration-normal;
	cursor: pointer;
}

a {
	text-decoration: none;
	color: currentColor;
}

svg {
	display: block;
	flex: 0 0 auto;
	width: 100%;
	height: 100%;
	fill: currentColor;
}

figure,
picture {
	display: inline-block;
	margin: 0;
	line-height: 0;

	img {
		width: 100%;
	}
}

img {
	vertical-align: top;
	max-width: 100%;
	user-select: none;
}

img[draggable="false"] {
	pointer-events: none;
	// stylelint-disable-next-line
	-webkit-user-drag: none;
}

fieldset {
	margin: 0;
	border: 0;
	padding: 0;
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

video {
	outline: none;
	width: 100%;
	height: 100%;
}

input[type="search" i]:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button {
	display: none;
}

input:-webkit-autofill {
    transition: all 5000s ease-in-out;
}

input[type="text"],
input[type="search"],
input {
	background: initial;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input,
select {
    outline: none;
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
    -webkit-text-fill-color: #314C6E !important;
}
