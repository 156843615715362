.news-inner {
  &__wrapper {
    margin: 0 auto;
	  width: 100%;
    padding: 20px 0 100px;
    max-width: 715px;
    color: #314c6e;
    padding-bottom: 55px;

    @include response(1023) {
      padding: 20px 0 60px;
      max-width: 100%;
    }
    @include response(767) {
      padding: 5px 0 40px;
    }
  }
  &__poster {
    width: 100%;
    // height: 390px;
    margin-bottom: 30px;
    position: relative;
    padding-top: 54.25%;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include response(767) {
      padding-top: 51.25%;
    }
  }

  &__section {
    @include response(1023) {
      padding:0 80px;
    }

    @include response(767) {
      padding:0 30px;
    }
  }

  &__date {
    @include flex(row, wrap, flex-start, center);
    font-size: 16px;
    letter-spacing: 0.01em;
    line-height: 23px;
    margin-bottom: 25px;
    span {
      margin-right: 20px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 25px;

    @include response(1023) {
      font-size: 22px;
      line-height: 33px;
    }
  }

  &__text {
    font-weight: normal;
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 40px;

    p {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}

.link-back {
  @include flex(row, nowrap, space-between, center);
  display: inline-flex;
  border-radius: 22px;
  padding: 0 12px;
  background: #fff;
  border: 0.5px solid #314c6e;
  font-size: 14px;
  line-height: 24px;
  height: 45px;
  background-color: #fff;
  transition: all $transition;

  svg {
    margin-right: 12px;
    width: 10px;
    height: 10px;
    flex: none;
  }

  @include desktop-hover {
    color: #fff;
    background-color: $red-400;
    border-color: $red-400;
  }
}

