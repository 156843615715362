code[class*="language-"],
pre[class*="language-"] {
	border-radius: 2px;
	color: $gray_dark;
	hyphens: none;
	line-height: 1.5;
	tab-size: 4;
	text-align: left;
	white-space: pre;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
  direction: ltr;
  font-family: Inconsolata, monospace;
  font-size: 13px;	
  letter-spacing: 0;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 18px 24px;
	margin: 0 0 24px;
	overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: $gray_lighter;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: 0 2px 1px;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: $gray;
}

.token.punctuation {
	color: #999;
}

.namespace {
	opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
	color: #EC4444;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #4ABF60;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
	color: #a67f59;
	background: hsla(0, 0%, 100%, .5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
	color: $blue;
}

.token.function {
	color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
	color: #e90;
}

.token.important,
.token.bold {
	font-weight: bold;
}
.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}
