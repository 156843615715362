.tabs {
  width: 100%;

  &__list {
    position: relative;
    z-index: 1;
    display: grid;
    grid-gap: 0.7vw;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &__item {
    @include flex(column, nowrap, center, center);
    position: relative;
    cursor: pointer;
    height: 276px;
    color: #fff;
    background-color: $red-500;
    transition: all $transition;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -22px;
      z-index: -1;
      display: block;
      border: 25px solid transparent;
      border-bottom: 25px solid  $red-500;
      border-left: 25px solid  $red-500;
      transform: translate3d(-50%, -50px, 0) rotate(-45deg);
      transition: transform $transition;
      will-change: transform;
    }

    &.active {
      background-color: $red-800;

      &::before {
        border-color: $red-800;
        transform: translate3d(-50%, 0, 0) rotate(-45deg);
      }
    }

    @include desktop-hover {
      background-color: $red-800;
    }

    @include response(1200) {
      height: 242px;
    }

    @include response(767) {
      height: 102px;

      &::before {
        border: 10px solid transparent;
        border-bottom: 10px solid #E40A13;
        border-left: 10px solid #E40A13;
        bottom: -2px;
      }
    }
  }

  &__icon {
    margin-bottom: 32px;
    height: 133px;

    @include response(1200) {
      margin-bottom: 25px;
      height: 93px;
    }

    @include response(767) {
      margin-bottom: 10px;
      height: 48px;
    }
  }

  &__subtitle {
    @include flex(row, nowrap, flex-start, center);
    position: relative;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: left;
    color: #314c6e;

    &::after {
      content: '\00a0–';
      display: block;
    }
    @extend %container;

    @include response(767) {
      max-width: calc(100% - 60px);
    }
  }

  &__caption {
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @include response(1200) {
      font-size: 17px;
    }

    @include response(767) {
      font-size: 10px;
    }
  }

  &__more {
    @extend %more;

    @include response(767) {
      font-size: 13px;
    }
  }

  &__content {
    position: relative;
    left: 50%;
    padding-top: 125px;
    padding-bottom: 60px;
    margin-top: -75px;
    width: 100vw;
    background: linear-gradient(#f8f7f7 0%, #fcfcfc 100%);
    transform: translate3d(-50%, 0, 0);

    &--list {
      display: grid;
			grid-gap: 2.5vw;
			grid-template-columns: 1fr 1fr;
      @extend %container;

      @include response(1023) {
        display: grid;
        grid-gap: 2.5vw;
        grid-template-columns: 1fr;
      }

      @include response(767) {
        display: grid;
        grid-gap: 1.5vw;
      }
    }

    @include response(767) {
      padding-top: 105px;
      padding-bottom: 40px;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.02em;
    color: $red-400;
    text-transform: uppercase;

    @include response(767) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }

  &__section {
    width: 100%;
  }

  &__product {
    border: 1px solid #e5e5e5;
    padding: 20px;
    background: #fff;
    display: none;

    @include response(1023) {
      max-width: 582px;
      width: 100%;
      justify-self: center;
    }

    @include response(767) {
      padding: 15px;
    }


    &.active {
      @include flex(row, nowrap, flex-start, center);

      @include response(767) {
        @include flex(column, nowrap, flex-start, center);
      }
    }
  }

  &__cover {
    margin-right: 20px;
    width: 100%;
    max-width: 250px;

    @include response(1200) {
      max-width: 180px;
    }

    @include response(1023) {
      max-width: 250px;
    }

    @include response(767) {
      max-width: 160px;
      height: 120px;
      margin: 0 auto;
      object-fit: contain;
      margin-bottom: 15px;
      // object-position: top;
    }
  }

  &__instructions{
    @include flex(row, nowrap, flex-start, flex-end);
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 16px;
    transition: color $transition;

    svg {
      margin-left: 10px;
      width: 19.05px;
      height: 25.8px;
    }

    @include desktop-hover {
      color: $red-400;
    }

    @include response(767) {
      font-size: 13px;
    }
  }

  @include response(1023) {
    width: 100vw;
  }
}