.footer {
	position: relative;
	width: 100%;
	padding-top: 50px;
	background-image: url(../images/footer-bg.png);
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;
	color: $white;
	@include tablet992 {
			background-image: url(../images/footer-bg-tablet.png);
			background-position: right top;
	}
	@include mobile576 {
			padding-top: 30px;
			background-image: url(../images/footer-bg-mobile.png);
	}

	&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			@include box(100%);
			background-color: rgba($red-400, .8);
	}

	&__row {
			position: relative;
			z-index: 1;
			display: flex;
			flex-basis: 25%;
			gap: 35px;
			padding-bottom: 100px;

			@include tablet992 {
					flex-wrap: wrap;
					justify-content: center;
					gap: 55px;
					padding-bottom: 50px;
			}

			@include mobile576 {
					gap: 45px 35px;
					padding-bottom: 30px;
			}
	}

	&__col {
			width: calc(100% / 4);
			padding-top: 18px;
			border-top: 1px solid $white;

			@include tablet992 {
					width: 260px;
			}

			@include mobile576 {

					&:first-child {
							width: 194px;
							order: 0;
					}

					&:nth-child(2) {
							width: 194px;
							order: 2;
					}

					&:nth-child(3) {
							width: 145px;
							order: 1;
					}

					&:last-child {
							width: 145px;
							order: 3;
					}
			}

			@include mobile375 {
					&:nth-child(n+1) {
							width: 100%;
					}
			}
	}

	&__subtitle {
			margin-bottom: 28px;
			@include font-size(15, 22);
			font-weight: 500;
			text-transform: uppercase;
			@include mobile576 {
					margin-bottom: 14px;
					@include font-size(14, 22);
			}
	}

	&__list {
			@include font-size(14);
			@include mobile576 {
					@include font-size(12, 18)
			}
	}

	&__item {
			&:not(:last-child) {
					margin-bottom: 14px;
			}
			a {
				transition: color $transition;
				@include desktop-hover {
					color: $dark-gray-100;
				}
			}
	}
}

.footer-copyright {
	position: relative;
	z-index: 1;
	padding: 34px 0;
	background-color: $dark-gray-600;
	@include font-size(13, 28);
	@include mobile576 {
			padding: 16px 0 20px;
			@include font-size(10, 18);
	}

	&__list {
			@include flex-center-vert;
			@include mobile576 {
					flex-wrap: wrap;
			}
	}

	&__item {
			margin-right: 50px;
			@include tablet992 {
					margin-right: 20px;
			}
			@include mobile576 {
					margin-right: 10px;

			}

			&:first-child {
					@include mobile576 {
							width: 100%;

					}
					@include mobile375 {
							width: auto;
					}
			}

			&:nth-child(2) {
					@include mobile375 {
							width: 50%;
					}
			}

			&:last-child {
					margin-left: auto;
					margin-right: 0;
			}

			a {
				transition: color $transition;
				@include desktop-hover {
					color: $red-400;
				}
			}
	}

	&__logo {
		display: block;
		transition: opacity $transition;

		img {
			width: 100%;
			height: 100%;
		}
		@include mobile576 {
				width: 84px;
		}
	}

	&__logo[href] {
		@include desktop-hover {
			opacity: 0.65;
		}
	}
}