.product {
    max-width: 716px;
    margin: 0 auto;
    padding: 60px 0;
    @include tablet768 {
        padding: 50px 40px;
    }
    @include mobile576 {
        padding: 40px 20px;
    }
    @include mobile375 {
        padding: 40px 0;
    }

    &__title {
        margin-bottom: 25px;
        @include font-size(22, 33);
        font-weight: 500;
        @include mobile576 {
            margin-bottom: 15px;
        }
    }

    &__list--links {
        margin-bottom: 30px;

        @include response(1023) {
            margin-bottom: 25px;
        }
    }

    &__list--item {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__caption {
        // margin-top: 58px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

        // @include response(1023) {
        //     margin-top: 50px;
        // }

        // @include response(767) {
        //     margin-top: 30px;
        // }
    }

    &__link {
        color: $red-400;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-decoration: underline;
        transition: all $transition;

        @include desktop-hover {
            text-decoration: none;
        }
    }

    &__text {
        @include font-size(17, 32);
    }

    &__list {
        background-color: $white-500;
    }

    &__item {
        padding: 60px 0 50px;
        border-bottom: 5px solid $white;
    }

    &__wrap {
        display: grid;
        align-items: flex-start;
        grid-template-columns: 300px 1fr;
        // grid-template-rows: minmax(150px, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-column-gap: 60px;


        // display: flex;
        // align-items: flex-start;
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        // grid-template-rows: 1fr 1fr 1fr 1fr;
        // grid-template-areas:
        //     "img content content"
        //     "section content content"
        //     "btn btn btn";
        padding: 0 60px;
        @include tablet768 {
            padding: 0;
            grid-template-columns: 215px 1fr;
            grid-column-gap: 30px;

        }
        @include response(767) {
            grid-column-gap: 0;
            grid-template-columns: 1fr;
        }
        @include mobile576 {
            padding: 0 15px;
            grid-template-columns: repeat(3, 1fr);

        }
        @include mobile375 {
            padding: 0;
        }
        @include response(767) {
            grid-template-columns: 1fr;
            grid-template-areas:
                "img"
                "content"
                "section"
                "btn";
        }
    }

    &__img {
        grid-column: 1;
        grid-row: 1 / 1;
        // grid-area: 1 / 1 / 2 / 2;
        // grid-area: img;
        // grid-row-start: 1;
        // grid-row-end: 2;
        // border: 1px solid red;
        @include response(767) {
            // grid-area: 1 / 1 / 2 / 2;
            margin-bottom: 30px;
        }
    }
    &__setion {
        grid-column: 1;

        // grid-area: section;
        // grid-row-start: 2;
        // grid-row-end: 3;
        // border: 1px solid red;
        margin-top: 58px;
        grid-area: 2 / 1 / 3 / 2;

        @include response(1023) {
            margin-top: 50px;
        }

        @include response(767) {
            margin-top: 30px;
            // grid-area: 3 / 1 / 4 / 2;
            grid-area: 3 / 1 / 4 / 2;
        }
    }

    &__content-wrap {
        grid-column: 2 / 3;
        grid-row: 1 / 12;
        // grid-area: 1 / 2 / 3 / 4;
        // grid-area: content;
        // grid-row-start: 1;
        // grid-row-end: 3;
        // border: 1px solid red;
        @include response(767) {
            grid-area: 2 / 1 / 3 / 2;
        }
    }

    &__inner {
        grid-column: 2;
        // grid-area: btn;
        // grid-area: 3 / 2 / 4 / 4;
        // grid-column-start: 2;
        // grid-column-end: 3;
        // grid-row-start: 3;
        // grid-row-end: 4;
        // border: 1px solid red;
        @include response(767) {
            grid-area: 4 / 1 / 5 / 2;
        }
    }

    &__img {
        max-width: 312px;
        // min-width: 312px;
        width: 100%;
        // min-height: 220px;
        // margin-right: 60px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        img {
            object-fit: contain;
        }

        @include tablet768 {
            max-width: 229px;
            // height: 162px;
            // max-width: 246px;
            // min-width: 246px;
            // margin-right: 20px;
        }
        @include mobile576 {
            min-height: 174px;
            margin-right: 0;
        }
    }

    &__content-wrap {
        max-width: 482px;
        // padding-top: 30px;
        @include tablet768 {
            // padding-top: 20px;
        }
        @include mobile576 {
            // padding-top: 27px;
        }
    }

    &__name {
        margin-bottom: 24px;
        @include font-size(28, 34);
        font-weight: 400;
        color: $red-400;
        @include tablet768 {
            margin-bottom: 20px;
        }
        @include mobile576 {
            margin-bottom: 24px;
        }
    }

    &__desc {
        @include font-size(17, 32);

        margin-bottom: 35px;

        @include mobile576 {
            margin-bottom: 25px;
        }

        &--medium {
            @include font-size(18, 24);
            letter-spacing: 0.02em;
            position: relative;
            &::after {
                margin-top: 35px;
                content: '';
                display: block;
                width: 110px;
                height: 3px;
                background: $dark-gray-600;

                @include response(767) {
                    margin-top: 50px;
                }
            }
        }

        p,
        li {
            position: relative;
            @include flex(row, nowrap, flex-start, center);
        }
    }

    &__btn {
        display: inline-block;
        margin-top: 35px;
        padding: 5px 35px;
        @include font-size(16, 34);
        font-weight: 500;
        color: $white;
        background-color: $red-400;
        transition: all .3s ease-in-out;

        &:hover {
            background-color: $red-800;
        }

        @include response(767) {
            margin-top: 35px;
        }
    }

}