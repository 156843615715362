.banner {
  @include flex(row, wrap, space-between, center);
  width: 100%;
  height: 515px;
  overflow: hidden;

  @include response(767) {
    height: auto;
  }

  &__cover {
    width: 58%;
    height: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      display: block;
      background-image: url("../images/banner/decor-2.png");
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      width: 80%;
      height: 111.03px
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }

    @include response(767) {
      width: 100%;
      height: 480px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-image: url("../images/banner/decor.svg");
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 120px
      }
    }
  }
  &__section {
    @include flex(column, nowrap,center, flex-start);

    position: relative;
    padding-right: 50px;
    width: 42%;
    height: 100%;
    color: #fff;
    background-color: $red-600;

    &::before {
      content: "";
      position: absolute;
      left: -205px;
      top: 0;
      display: block;
      background-image: url("../images/banner/decor.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 240px;
    }

    @include response(1023) {
      padding-right: 10px;
    }

    @include response(767) {
      padding: 0 32px;
      width: 100%;
      margin-top: -40px;
      padding-bottom: 50px;

      &::before {
        position: absolute;
        left: 0;
        top: -105px;
        background-image: url("../images/banner/decor-2.svg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px
      }
    }
  }

  &__info {
    position: relative;
    left: -60px;

    @include response(1023) {
      left: -70px;
    }

    @include response(767) {
      left: 0;
    }
  }

  &__title {
    margin-bottom: 25px;
    width: 100%;
    font-size: 34px;
    line-height: 34px;
  }

  &__desc {
    margin-bottom: 40px;
    width: 100%;
    font-size: 17px;
    line-height: 28px;
  }

  &__link {
    color: $red-400;;
    background-color: $white;
    transition: color $transition;

    @include desktop-hover {
      color: $red-800;;
    background-color: $white;
    }
    @extend %btn;
  }
}