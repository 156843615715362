.filter {
    width: 100%;

    // &__container {
    //     @include min-response(1023) {
    //         &:not(:last-child) {
    //             margin-bottom: 15px;
    //         }
    //     }
    // }

    &__section {
        margin-bottom: 40px;
        width: 100%;
    }

    &__title {
        position: relative;
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 30px;

        &::before {
            content: "";
            position: absolute;
            right: 40px;
            top: 50%;
            display: none;
            border-right: 2px solid #D9D9D9;
            border-bottom: 2px solid #D9D9D9;
            width: 5px;
            height: 5px;
            transform: rotate(45deg) scale(-1);
            transform-origin: 66% 66%;
            transition: all 0.15s ease-in-out;
            pointer-events: none;
        }

        @include response(1200) {
            border-bottom: 0.5px solid #D9D9D9;
            padding: 5px 25px;

            &::before {
                display: block;
            }

        }
    }

    &__list {
        margin-bottom: 15px;
        @include min-response(1200) {
            display: block !important;

        }
        @include response(1200) {
            margin-top: 15px;
            margin-bottom: 15px;
            padding: 0 27px !important;
        }
    }


    &__item {
        @include flex(row, nowrap, flex-start, center);
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__checkbox {
        @include flex(row, nowrap, flex-start, center);
        display: inline-flex;
        cursor: pointer;


        input {
            &:checked ~ .filter__icon {
                border-color: $red-500;
                background: $red-500;
                path {
                  animation: stroke 0.3s forwards;
                }
              }
        }

        p {
            margin: 0 8px;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #727880;
            transition: transform 0.3s ease-in-out;
        }

        @include desktop-hover {
            p {
                color: $dark-gray-400;
            }
        }
    }

    &__counter {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #D9D9D9;
    }

    &__icon {
        position: relative;
        flex: 0 0 12px;
        overflow: hidden;
        border: 0.25px solid #314C6E;
        width: 12px;
        height: 12px;



        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          display: block;
          flex: 0 0 100%;
          width: 100%;
          height: 100%;
          max-width: none;
          stroke: #fff;
          stroke-miterlimit: 3;
          stroke-width: 3;
          transform: translate3d(-50%, -50%, 0) scale(1.5);
          path {
            stroke-dasharray: 35;
            stroke-dashoffset: 35;
          }
        }
    }

    &__navigation {
        @include flex(column, nowrap, flex-start, center);
        margin-top: auto;
        width: 100%;

        @include response(1200) {
            position: sticky;
            bottom: 0;
            z-index: 10;
            padding-bottom: 20px;
            background: #fff;

        }
    }

    &__button {
        @extend %btn;
        margin-bottom: 20px;
        width: 100%;
    }

    &__reset {
        @include flex(row, nowrap, center, center);
        display: inline-flex;
        margin: 0 auto;
        color: $dark-gray-100;
        transition: color 0.3s ease-in-out;

        svg {
            flex: 0 0 21px;
            margin-right: 8px;
            width: 21px;
            height: 18px;
            fill: currentColor;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.01em;
            text-align: center;
            color: currentColor;
        }

        @include desktop-hover {
            color: $dark-gray-400;
        }
    }

    &__container {

        @include response(1200) {
            &._show {
                .filter__title {
                    background: #F9F9FA;

                    &::before {
                        border-color: #314C6E;
                        transform: rotate(45deg) scale(1);
                    }
                }

                // .filter__list {
                //     margin-top: 15px;
                //     margin-bottom: 15px;
                //     max-height: 500px;
                // }
            }
        }
    }

    @include response(1200) {
        @include flex(column, nowrap, flex-start, flex-start);
        flex: 1 1 auto;
        width: 100%;
    }
}

@keyframes stroke {
    100% {
      stroke-dashoffset: 1;
    }
}
