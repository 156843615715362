.achievements {
    max-width: 514px;
    width: 31%;
    background: linear-gradient(#fff 0%, #fff 2.96%, #fff 100%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    &__cover {
        width: 100%;
        height: 235px;
        object-fit: cover;

        @include response(1023) {
            height: 245px;
        }
        @include response(767) {
            height: 59vw;
        }
    }

    &__icon {
        max-width: 235px;
        height: 70px;
        object-fit: contain;
        margin-bottom: 20px;

        @include response(767) {
            max-width: 178px;
            height: 52px;
        }
    }

    &__section {
        padding: 30px 50px 50px;

        @include response(767) {
            padding: 25px 25px 50px;
        }
    }

    &__title {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: #314c6e;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #314c6e;
        margin-bottom: 9px;
        margin-top: 9px
    }

    &__desc {
        font-weight: 400;
        font-size: 13px;
        line-height: 28px;
        color: #314c6e;
    }

    @include response(1023) {
        width: 100%;
        max-width: 544px;
        margin-bottom: 10px;
    }
}
