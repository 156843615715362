.medical-library {
    width: 100%;

    &__container {
        padding: 20px 0 90px;
        @include tablet768 {
            padding: 15px 0 60px;
        }
        @include mobile576 {
            padding: 5px 0 40px;
        }
    }

    &__top {
        @include flex(row, nowrap, space-between, center);
        margin-bottom: 20px;

        @include response(767) {
            @include flex(column, nowrap, flex-start, flex-start);
            margin-bottom: 4em;
        }
    }

    #logout {
        @include font(14, false, false, 28, 400);
        color: #314C6E;

        @include response(767) {
            margin-top: 15px;
            margin-left: auto;
        }
    }

    &__section {
        width: 100%;
    }

    &__title {
        margin-right: 20px;
        font-weight: 300;
        font-size: 40px;
        color: $red-500;
        @include mobile576 {
            @include font-size(40, 44);
            margin-right: 0;
        }

        @include response(767) {
            @include font(30, false, false, 30, 300);
        }
    }

    &__desc {
        margin-top: 20px;
        margin-bottom: 55px;
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        a {
            font-weight: 400;
            color: #3A86ED;
        }
        @include tablet768 {
            margin-bottom: 30px;
        }
    }

    ._error__text {
        @include flex(row, wrap, flex-start, center);
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $red-500;

        svg {
            flex: 0 0 16px;
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }
    }

    &__label {
        @include flex(row, wrap, space-between, center);
        position: relative;
        width: 100%;
        max-width: 440px;

        &:not(:last-child) {
            margin-bottom: 15px;
        }



        &._error {
            .custom-select,
            input {
                border-color: $red-500 !important;
            }

            ._error__text {
                display: flex;
            }
        }

        &--password {
            .medical-library__icon {
                position: absolute;
                right: 20px;
                top: auto;
                width: 23px;
                height: 16px;
                color: #D9D9D9;
                transition: color 0.3s ease-in-out;
                cursor: pointer;

                svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

                @include desktop-hover {
                    color: #4D637E;
                }
            }

            .hide-pasword {
                display: block;
            }

            .show-password {
                display: none;
            }

            &.show {
                .hide-pasword {
                    display: none;
                }

                .show-password {
                    display: block;
                }
            }
        }

        ._error__text {
            display: none;
            @include min-response(767) {
                position: absolute;
                left: calc(100% + 20px);
                top: auto;
            }
        }

        &.focus {
            input {
                border-color: #314C6E;
            }
        }
    }

    &__button {
        &._no-active {
            pointer-events: none;
            color: #D9D9D9;
            background: #F9F9FA;
            border: 0.25px solid #727880;
        }
    }


    &__form {
        @include flex(column, nowrap, flex-start, flex-start);
        margin-bottom: 45px;
        width: 100%;


        .custom-select {
            @include flex(row, wrap, flex-start, center);
            border: 1px solid #D9D9D9;
            border-radius: 0;
            width: 100%;
            height: 45px;

            ._error & {
                color: red;
            }
        }

        .nice-select-dropdown  {
            left: -1px !important;
            width: calc(100% + 2px) !important;
            transform: scale(1) translateY(-19px) !important;
        }


        .nice-select-dropdown,
        .nice-select.open .nice-select-dropdown {
          border: none;
          border-radius: 0;
          width: calc(100% + 2px) !important;
          transform: scale(1) translateY(0) !important;
        }

        .nice-select .option.selected {
            font-weight: normal;
        }

        .nice-select:active,
        .nice-select.open,
        .nice-select:focus {
            border-color: #314C6E;
        }

        select,
        input {
            outline: none;
            border: 1px solid #D9D9D9;
            padding-right: 15px;
            padding-left: 15px;
            width: 100%;
            height: 45px;
            font-weight: 700;
            font-size: 13px;
            line-height: 22px;
            color: #314C6E;
            transition: all 0.3s ease-in-out;

            &::placeholder {
                font-weight: 700;
                font-size: 13px;
                line-height: 22px;
                color: #314C6E;
            }

            &:focus {
                border-color: #314C6E;
            }
        }
    }

    .checkbox {
        @include flex(row, nowrap, flex-start, flex-start);
        margin-top: 5px;
        margin-bottom: 10px;
        // max-width: inherit;
        cursor: pointer;

        input {
            display: none;
        }

        .cbx {
            &:checked {
                ~ .checkbox__check {
                    border-color: $red-500;
                    background: $red-500;
                    path {
                      animation: stroke 0.3s forwards;
                    }
                }
            }
        }

        &__check {
            position: relative;
            flex: 0 0 16px;
            margin-right: 10px;
            border: 1px solid #727880;
            width: 16px;
            height: 16px;

            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                display: block;
                flex: 0 0 100%;
                width: 100%;
                height: 100%;
                max-width: none;
                stroke: #fff;
                stroke-miterlimit: 3;
                stroke-width: 3;
                transform: translate3d(-50%, -50%, 0) scale(1.5);
                path {
                  stroke-dasharray: 35;
                  stroke-dashoffset: 35;
                }
              }

            // &::before {
            //     content: "";
            //     position: absolute;
            //     left: 50%;
            //     top: 50%;
            //     display: block;
            //     width: 10px;
            //     height: 10px;
            //     background-color: $red-500;
            //     transform: translate3d(-50%, -50%, 0) scale(0);
            //     transition: transform 0.3s ease-in-out;
            // }

        }

        &__title {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        &._error {
            .checkbox__check {
                border-color: $red-500 !important;
            }
        }
    }

    &__content {
        @include flex(row, nnowrap, space-between, flex-start);
        // height: 400px;
        // border: 1px solid red;
    }

    &__filter {
        flex: 0 0 230px;
        padding-right: 15px;

        &--navigation {
            @include flex(row, nowrap, space-between, center);
            display: none;
            padding: 20px 30px;
            width: 100%;

            @include response(1200) {
                position: sticky;
                top: 0;
                z-index: 10;
                display: flex;
                box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
                background-color: #fff;
            }

            @include response(767) {
                padding: 15px;
            }
        }

        @include response(1200) {
            @include flex(column, nowrap, flex-start, flex-start);
            position: fixed;
            left: 0;
            top: 0;
            z-index: 10;
            display: flex;
            flex: none;
            overflow: auto;
            padding-right: 0;
            width: 100%;
            height: 100vh;
            background-color: #fff;
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: all 0.3s ease-in-out;
            pointer-events: none;

            &._active {
                opacity: 1;
                transform: translate3d(-0%, 0, 0);
                pointer-events: all;
            }
        }
    }

    &__close {
        order: 2;
        width: 10px;
        height: 10px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__section {
        flex: 1 1 auto;
        // border: 1px solid red;
    }

    &__inner {
        @include flex(row, wrap, space-between, center);

        @include response(1023) {
            margin-bottom: 10px;
        }

    }

    #filter {
        @include flex(row, nowrap, space-between, center);
        display: none;
        margin-right: 15px;
        font-weight: 700;
        font-size: 13px;
        line-height: 28px;
        color: #314C6E;

        svg {
            margin-left: 15px;
            width: 18px;
            height: 12px;
        }

        @include response(1200) {
            display: flex;
            order: 1;
        }
    }

    &__length {
        font-weight: 400;
        font-size: 13px;
        line-height: 28px;

        @include response(1200) {
            order: 3;
            margin-top: 15px;
            width: 100%;
        }
    }

    &__excaptioncerpt {
        margin-top: 35px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        a {
            font-weight: 700;
            text-decoration: underline;
        }

        @include response(767) {
            margin-top: 20px;
        }
    }

    &__sort {
        @include flex(row, nowrap, flex-start, center);
        position: relative;
        p {
            margin-right: 7px;
            font-weight: 400;
            font-size: 13px;
            line-height: 28px;
        }

        .nice-select {
            position: unset;
            border: none !important;
            padding: 0;
            padding-right: 30px;
            height: auto;
            line-height: 28px;

            .current {
                font-weight: 700;
                font-size: 13px;
                line-height: 28px;
            }

            .nice-select-dropdown {
                border: 1px solid #D9D9D9 !important;
                border-radius: 0;
                width: 100%;
                box-shadow: none !important;
                background: #fff;

                .option {
                    font-weight: 400;
                }
            }

            @include response(767) {
                padding-right: 15px;
                &::after {
                    right: 0;
                }
            }
        }

        @include response(1200) {
            order: 2;
        }
    }

    &__result {
        margin-bottom: 60px;
        width: 100%;

        @include response(767) {
            margin-bottom: 30px;
        }
    }

    @import "./medical-library/result-item";
    @import "./medical-library/not-found";
    @import "./medical-library/search";
    @import "./medical-library/filters";
}

