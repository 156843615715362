.pages-main-screen {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // min-height: 380px;
    min-height: 525px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: $white;

    @include response(1679) {
        min-height: 380px;
    }
    @include tablet768 {
        min-height: 325px;

        &._history {
            background-position: left center;
        }

        &._history-russia {
            // background-image: url(../images/about/history-russia-page-tablet.png) !important;
        }
    }
    @include mobile576 {
        min-height: 240px;

        &._activity {
            background-position: 65%;
        }

        &._activity-russia {
            background-image: url(../images/shadows.png), url(../images/about/activity-russia-page.png) !important;
            background-repeat: no-repeat;
            background-position: bottom, 40% 0;
            background-size: cover;
        }

        &._history-russia {
            // background-image: url(../images/about/history-russia-page-mob.png) !important;
        }
    }

    &._main-russia {
        background-position: top center;
        @include mobile576 {
            // background-position: 40% 100%;
        }
    }

    &__title {
        @include font-size(50, 56);
        max-width: 580px;
        width: 100%;
        font-weight: 700;
        @include response(767) {
            max-width: 100%;
        }
        @include mobile576 {
            padding-left: 25px;
            padding-right: 60px;
            letter-spacing: 0.02em;
            @include font-size(38, 42);
        }
        @include mobile375 {
            padding-left: 0;
        }
    }

    &__subtitle {
        margin-bottom: 9px;
        @include font-size(18, 26);
        font-weight: 500;
        @include mobile576 {
            padding-left: 25px;
            margin-bottom: 1px;
            letter-spacing: 0.02em;
            @include font-size(14, 26);
        }
        @include mobile375 {
            padding-left: 0;
        }
    }

    .container {
        position: relative;
    }

    &__play {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 496px;
        transition: all .3s ease-in-out;
        font-size: 0;
        @include tablet1024 {
            right: 34px;
        }
        @include mobile576 {
            right: 15px;

            img {
                width: 53px;
                height: 53px;
            }
        }

        &:hover {
            background-color: rgba(255, 255, 255, 40%);
        }
    }
}