.corporate {
    &__text-wrap {
        max-width: 716px;
        margin: 0 auto;
        @include tablet768 {
            padding: 0 40px;
        }
        @include mobile576 {
            padding: 0 20px;
        }
        @include mobile375 {
            padding: 0;
        }
    }

    &__title {
        margin-bottom: 25px;
        @include font-size(22, 33);
        font-weight: 500;
        @include mobile576 {
            margin-bottom: 15px;
        }
    }

    &__text {
        @include font-size(17, 32);
    }
}

.corporate-responsibility {
    padding-top: 60px;
    @include tablet768 {
        padding-top: 60px;
    }
    @include mobile576 {
        padding-top: 40px;
    }

    .corporate__text-wrap {
        margin-bottom: 50px;
        @include mobile576 {
            margin-bottom: 30px;
        }
    }
}

.corporate-culture {
    .corporate__text-wrap {
        padding-bottom: 30px;
        @include tablet768 {
            padding-bottom: 40px;
        }
        @include mobile576 {
            padding-bottom: 25px;
        }
    }

    &__principles {
        position: relative;
        margin-top: 100px;
        padding: 140px 0 60px;
        background-color: $white-500;
        @include tablet768 {
            margin-top: 88px;
        }
        @include mobile576 {
            padding: 68px 0 50px;
        }
        @include mobile375 {
            margin-top: 41px;
            padding-top: 51px;
        }

        .principles {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 12.5px;
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translateX(-50%);
            max-width: 764px;
            width: 100%;
            padding: 35px 35px 0;
            @include tablet768 {
                top: -92px;
            }
            @include mobile576 {
                gap: 6px;
                top: -68px;
            }
            @include mobile375 {
                top: -63px;
            }

            &__sphere {
                position: relative;
                @include flex-center-column;
                align-items: center;

                &:first-child {
                    .principles__sphere-inner {
                        @include font-size(22, 26);
                        @include mobile576 {
                            @include font-size(16, 26);
                        }
                    }
                }

                &:nth-child(even) {
                    .principles__arc {
                        top: auto;
                        bottom: -35px;
                        border-radius: 0 0 50% 50% / 0 0 100% 100%;
                        @include tablet768 {
                            bottom: -30.5px;
                        }
                        @include mobile576 {
                            bottom: -17.5px;
                        }
                        @include mobile375 {
                            bottom: -12.5px;
                        }
                    }
                }

                &._active {
                    .principles__sphere-inner {
                        color: $dark-gray-600;

                        &::after {
                            height: 82px;

                            @include mobile576 {
                                height: 35px;
                            }
                            @include mobile375 {
                                height: 25px;
                            }
                        }
                    }

                    // .principles__line {
                    //     opacity: 1;
                    //     visibility: visible;
                    // }
                }
            }

            &__sphere-inner {
                position: relative;
                z-index: 2;
                @include flex-center;
                width: 128px;
                min-width: 128px;
                height: 128px;
                min-height: 128px;
                border-radius: 50%;
                background-color: $white;
                border: 12px solid $white-400;
                color: $red-500;
                font-weight: 700;
                @include font-size(42, 26);
                text-transform: uppercase;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 16%);
                cursor: pointer;
                transition: all .3s linear;

                &::after {
                    transform: translate3d(-50%, 0, 0);
                    position: absolute;
                    left: 50%;
                    top: calc(100% + 12px);
                    content: "";
                    display: block;
                    z-index: 2;
                    width: 1px;
                    height: 0;
                    background-color: $dark-gray-700;
                    transition: all .3s linear;
                }
                @include tablet768 {
                    width: 113px;
                    min-width: 113px;
                    height: 113px;
                    min-height: 113px;
                }
                @include mobile576 {
                    width: 65px;
                    min-width: 65px;
                    height: 65px;
                    min-height: 65px;
                    @include font-size(24, 26);
                }
                @include mobile375 {
                    width: 55px;
                    min-width: 55px;
                    height: 55px;
                    min-height: 55px;
                    @include font-size(32, 26);
                }
            }

            &__arc {
                position: absolute;
                z-index: 1;
                top: -35px;
                left: -35px;
                width: calc(100% + 70px);
                height: 99px;
                border-radius: 50% 50% 0 0 / 100% 100% 0 0;
                @include tablet768 {
                    width: calc(100% + 61px);
                    height: 87px;
                    top: -30.5px;
                    left: -30.5px;
                }
                @include mobile576 {
                    width: calc(100% + 35px);
                    height: 50px;
                    top: -17.5px;
                    left: -17.5px;
                }
                @include mobile375 {
                    width: calc(100% + 25px);
                    height: 40px;
                    top: -12.5px;
                    left: -12.5px;
                }

                &._first {
                    background-color: $dark-gray-600;
                }

                &._second {
                    background-color: $light-blue-400;
                }

                &._third {
                    background-color: $red-100;
                }

                &._fourth {
                    background-color: $red-800;
                }

                &._fifth {
                    background-color: $red-500;
                }
            }

            &__line {
                position: relative;
                z-index: 2;
                width: 1px;
                height: 82px;
                background-color: $dark-gray-700;
                opacity: 0;
                visibility: hidden;
                transition: all .3s linear;
                @include mobile576 {
                    height: 35px;
                }
                @include mobile375 {
                    height: 25px;
                }
            }
        }

        .principles-desc {
            position: relative;
            max-width: 654px;
            min-height: auto;
            margin: 7px auto 0;
            text-align: center;

            &__wrap {
                position: absolute;
                top: 0;
                left: 0;
                visibility: hidden;
                opacity: 0;
                transform-origin: 0 0;
                transform: translate3d(0, 100%, 0);
                transition: opacity 0.55s linear, transform 0.3s linear;

                &._active {
                    position: static;
                    visibility: visible;
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }

            &__title {
                margin-bottom: 15px;
                @include font-size(18, 26);
                font-weight: 700;
                color: $red-500;
                text-transform: uppercase;

                span {
                    color: $dark-gray-600;
                }
            }

            &__text {
                @include font-size(15, 24);
                font-weight: 300;
            }
        }
    }
}

.corporate-docs {
    padding: 70px 0 80px;
    @include tablet768 {
        padding: 50px 0 40px;
    }
    @include mobile576 {
        padding: 40px 0 20px;
    }

    .docs {
        &__list {
            max-width: 960px;
            margin: 40px auto 0;
            @include tablet768 {
                margin-top: 30px;
            }

            &:first-of-type {
                margin-bottom: 70px;
                @include tablet768 {
                    margin-bottom: 50px;
                }
            }
        }

        &__item {
            margin-bottom: 20px;
            border: 1px solid $gray-600;
        }

        &__link {
            @include flex-center-vert;
            justify-content: space-between;
            padding: 25px 95px 25px 90px;
            transition: all .3s ease-in-out;
            @include tablet768 {
                padding: 25px 55px 25px 40px;
            }
            @include mobile576 {
                padding: 25px 50px 25px 20px;
            }

            &:hover {
                color: $red-500;
            }
        }

        &__text {
            @include font-size(17, 32);
            font-weight: 500;
        }

        &__icon {
            width: 34px;
            height: 46px;
            margin-left: auto;
            @include mobile576 {
                margin-left: 15px;
            }

            &--pdf {
                color: #FF8A80;
                fill: $red-500;
            }

            &--xls {
                color: #9FE3B9;
                fill: $green-200;
            }
        }
    }
}