.news {
  &__wrapper {
    @extend %container;
    margin: 0 auto;
	  width: 100%;
    padding-bottom: 55px;

    .container {
      @include response(1200) {
        width: 100%;
        max-width: 100%;
      }

      @include response(767) {
        max-width: calc(100% - 30px);
      }
    }

    @include response(767) {
      padding: 20px 0 40px;
    }
  }

  &__section {
    @include flex(row, wrap, space-between, center);
    margin-bottom: 35px;
  }

  &__title {
    @include font-size(40, 25);
    font-weight: 300;
    line-height: normal;
    color: $red-500;
    width: calc(100% - 200px);

    @include mobile576 {
        @include font-size(40, 44);
        margin-bottom: 15px;
        width: 100%;
    }
  }

  &__list {
    display: grid;
    grid-column-gap: 2.5vw;
    grid-template-columns: repeat(4, 1fr);

    @include response(1023) {
      grid-column-gap: 4vw;
      grid-template-columns: repeat(2, 1fr);
    }

    @include response(767) {
      grid-column-gap: 0;
      grid-template-columns: 1fr;
    }
  }

  &__select  select{
    outline: none;
    border: 1px solid #e5e5e5;
    padding: 0 30px;
    width: 168px;
    height: 52px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    background: #fff;
  }

  .nice-select {
    @include flex(row, wrap, flex-start, center);
    border-radius: 0;
    outline: none;
    border: 1px solid #e5e5e5;
    padding: 0 30px;
    width: 168px;
    height: 52px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.01em;
    background: #fff;

    &::after {
      content: '';
      display: block;
      border-color: $red-500;
      right: 25px;
    }

    .list {
      border-radius: 0;
    }

    .option,
    .selected,
    .current {
      font-weight: 500 !important;
      font-size: 16px;
      letter-spacing: 0.01em;
    }

    .option {
      transition: all $transition;
      @include desktop-hover {
        background: rgba(242, 242, 242, 0.4);
        color: #e30613;
      }
    }
  }

  .nice-select-dropdown,
  .nice-select.open .nice-select-dropdown {
    border: none;
    border-radius: 0;
    width: 100%;
  }

  .nice-select:active,
  .nice-select.open, .nice-select:focus {
    border: 1px solid #e5e5e5;
  }
}

