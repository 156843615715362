.breadcrumb {
    @include flex-center-vert;
    padding: 20px 0 15px;
    @include mobile576 {
        padding: 12px 0 15px;
        overflow-x: auto;
        margin-left: 0px;
    }

    &__item {
        position: relative;
        padding-right: 28px;
        @include font-size(12, 28);
        color: $dark-gray-100;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            width: 5px;
            height: 9px;
            background-image: url(../images/chevron-right-gray.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        &:last-child {
            color: $dark-gray-300;
            &:before {
                display: none;
            }

            .breadcrumb__link {
                pointer-events: none;
            }
        }

        @include response(767) {
            padding-right: 16px;

            &::before {
                right: 5px;
            }
        }

        @include response(413) {
            font-size: 10px;
            padding-right: 12px;

            &::before {
                right: 3px;
            }
        }
    }

    &__link {
        white-space: nowrap;
    }
}