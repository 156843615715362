.cover {
	@include flex(column, nowrap, flex-start, flex-start);
	position: relative;
	width: 100%;
	height: 600px;

	@include response(767) {
		height: auto;
		background-color: $dark-gray-600;

		&::before {
			position: absolute;
            right: -14em;
            top: -13em;
			content: "";
			display: block;
			background: #314c6e;
			opacity: 0.89;
			width: 455px;
			height: 300px;
			z-index: 2;
			border-radius: 50%;
			transform: rotate(67deg);
		}

		&::after {
			position: absolute;
			left: 0;
			top: 155px;
			content: '';
			z-index: 1;
			display: block;
			width: 100%;
			height: 300px;
			background-image: url("../images/shape.svg");
			background-repeat: no-repeat;
			background-size: contain;
			pointer-events: none;
			background-position: left;
		}
	}

	&__decor {
    position: absolute;
    left: 0;
    top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include response(767) {
			display: none;
		}
  }

	&__img {
		position: absolute;
    right: 0;
		top: 0;
		z-index: -1;
    width: 66%;
		height: 100%;
		object-fit: cover;

		@include response(767) {
			width: 100%;
			height: 300px;
			z-index: 1;
			object-position: right;
		}
	}

	.navbar__submenu-container {
		@include response(767) {
			top: 60px;
		}
	}

	&__navigation {
		margin-top: auto;
		margin-bottom: 110px;
	}

	.header {
		position: relative;
		z-index: 200;
		width: 100%;
		background-color: #fff0;

		&.open {
			background-color: $white;
		}

		&.cover__navigation {
			z-index: 2;
		}
	}

    .header__link--main {
        color:  #fff;


        .cls-1 {
            stroke: #fff;
            transition: all 0.25s linear;

        }

        @include desktop-hover {
            color: #E40A13;

            .cls-1 {
                stroke: #E40A13;
            }
        }
    }

	.header__search {
		color:  #fff;

		&:hover {
			color: $red-500;

			.search-svg {
					color: $red-500;
					stroke: $red-500;
			}
		}

		@include response(767) {
			span {
				display: none;
			}
		}

		&.open {
			transition: all .25s ease-in-out;
			color: #fff;
			background: #e40a13;
			.search-svg {
				display: none;
			}
			.search-svg--close {
				display: block;
			}

			@include desktop-hover {
				color: #fff;
				background-color: #314C6E;
			}
		}
	}

	.search-svg {
		color: #fff;
		transition: all 0.25s linear;
	}

	.header__bottom > .container {
		@include response(1023) {
			@include flex(row, wrap, flex-end, center);
			margin: 0;
			margin-left: auto;
			max-width: calc(100% - 40px);
		}

		@include response(767) {
			@include flex(row, wrap, center, center);
			position: relative;
			padding:  0 40px;
			max-width: 100%;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				display: block;
				width: 100%;
				height: 60px;
				background-color: #E30613;
			}
		}
	}

	.navbar__menu {
		@include response(767) {
			@include flex(row, wrap, flex-start, flex-start);
		}
	}

	.navbar__link {
		@include response(1023) {
			padding: 15px 32px;
		}
		@include response(767) {
			@include flex(row, wrap, center, center);
			height: 100%;
		}
	}

	.navbar__item {
		@include response(767) {
			width: 50% !important;
			height: 60px;
			flex: none;
		}
	}

	.header__bottom {
		@include response(767) {
			background-color: inherit;
		}
	}

	&__wrapper {
		@extend %container;
		padding-top: 145px;
		position: relative;
		z-index: 2;

		@include response(767) {
			padding-top: 185px;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 44px;
		line-height: 50px;
		letter-spacing: 0.02em;
		text-align: left;
		color: #fff;

		@include response(1200) {
			font-size: 28px;
			line-height: 50px;
			letter-spacing: 0.02em;
		}

		@include response(767) {
			font-size: 28px;
			letter-spacing: 0.02em;
			line-height: 30px;
			margin-bottom: 35px;
		}
	}
}
