.logo-wrap {
  @include flex-center-vert;
  max-width: 960px;
  width: 100%;
  margin: 40px auto 0;
  padding: 20px 47px;
  border: 1px solid $gray-600;
  @include tablet768 {
    max-width:100%;
    width: 100%;
  }
  @include response(767) {
      flex-direction: column;
      align-items: flex-start;
      max-width:100%;
      width: 100%;
      margin: 30px 0 40px 0;
      padding: 25px;
      padding-bottom: 35px;
  }

  img {
      object-fit: contain;
      width: 165px;
      max-height: 100px;
      // height: 46px;
      margin-right: 55px;
      @include mobile576 {
          max-width: 171px;
          width: auto;
          // height: 48px;
          margin-bottom: 30px;
          margin-right: 0;
      }
  }

  &__text {
      max-width: 557px;
      @include font-size(15, 24);
      font-weight: 500;
      text-transform: uppercase;
      span {
          color: $red-400;
      }
      @include tablet768 {
          max-width: 388px;
      }
  }
}