.not-found {
    margin: 0 auto;
    margin-top: 20px;
    padding: 0;
    width: 100%;
    max-width: 680px;

    &__container {
        @include flex(row, nowrap, flex-start, center);

        @include response(767) {
            @include flex(column, nowrap, flex-start, center);
        }
    }

    &__icon {
        flex: 0 0 180px;
        margin-right: 45px;
        width: 180px;
        height: 171px;

        @include response(1023) {
            flex: 0 0 130px;
            width: 130px;
            height: 123px;
        }

        @include response(767) {
            position: relative;
            left: 0;
            flex: 0 0 15em;
            margin-right: 0;
            margin-bottom: 5em;
            width: 15em;
            height: 142px;
        }
    }

    &__title {
        @include font(24, false, false, 34, 300);
        margin-bottom: 15px;
        color: $red-500;
    }

    &__description {
        @include font(18, false, false, 24, 400);
    }

    &__link {
        @include font(18, false, false, 24, 400);
        text-decoration-line: underline;

        @include response(767) {
            br {
                display: none;
            }
        }
    }

    @include response(1023) {
        max-width: 640px;
    }
}
