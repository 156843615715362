.search {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: $red-500;
  padding: 110px 40px 165px;
  opacity: 0;
  pointer-events: none;
  display: block;
  // display: none;

  @include response(1023) {
    padding: 130px 40px 170px;
  }

  @include response(767) {
    padding: 75px 0 120px;
  }

  &.open {
    transition: opacity 0.25s linear;
    // display: block;
    opacity: 1;
    pointer-events: all;
  }

  &__wrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    @include response(1023) {
      max-width: 688px;
    }
    @include response(767) {
      max-width: 100%;
    }
  }

  &__form {
    position: relative;
    width: 100%;
    height: 80px;
    background-color: #fff;

    @include response(767) {
      height: 55px;
    }
  }

  &__input {
    outline: none;
    padding: 0 30px;
    padding-right: 60px;
    width: 100%;
    height: 100%;
    color: #10384f;
    border: none;

    &:focus {
      border: none;
    }

    @include response(767) {
      padding: 0 50px 0 15px;
    }
  }

  &__button {
    @include flex(row, wrap, center, center);
    position: absolute;
    clip-path: polygon(35% 0%, 100% 0, 100% 100%, 0% 100%);
    right: 0;
    top: 0;
    height: 100%;
    width: 135.93px;
    background-color: #314c6e;
    transition: all $transition;
    color: #fff;

    svg {
      position: relative;
      width: 28.71px;
      height: 28.71px;
      left: 10px;
    }

    @include response(767) {
      width: 97.59px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    @include desktop-hover {
      background-color: $red-400;
    }
  }

  &__title {
    color: #fff;
    width: 100%;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 44px;
    letter-spacing: 0.02em;
    line-height: 56px;

    @include response(767) {
      padding-left: 10px;
      font-size: 22px;
      letter-spacing: 0.02em;
      line-height: 33px;
      margin-bottom: 20px;
    }
  }
}