.disclaimer {
    display: none;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;

    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: #000000c4;

    &--active {
        display: flex;
    }

    &__wrapper {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-width: 50%;
    }

    &__btns {
        display: flex;
        gap: 15px;
        margin-top: 20px;
    }

    &__btn {
        border: 1px solid #1c59b5;
        padding: 8px 15px;
        border-radius: 20px;
        background: #a8d5ff;
        transition: 0.3s;

        &:hover {
            background: #1c59b5;
            color: white;
        }

        &--yes {
            background: #1c59b5;
            color: white;

            &:hover {
                background: #a8d5ff;
                color: black;
            }
        }
    }
}