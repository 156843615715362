.vacancies {
    width: 100%;

    .card-chapter__description {
        a {
            text-decoration: underline;
            transition: color $transition;

            @include desktop-hover {
                color: $red-400;
            }
        }

        a[href^="mailto:"] {
            text-decoration: none;
            color: #3583f0;

            // @include desktop-hover {
            //   color: $red-400;
            // }
        }
    }


    .pages-main-screen {
        background-position: 0 34%;

        @include response(767) {
            background-position: center;
        }
    }

    &__list {
        @include flex(column, wrap, flex-start, center);
        position: relative;
        // background: #fcfcfc;

        @include response(1023) {
            padding-bottom: 45px;
        }
    }

    &__subtitle {
        width: 100%;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 35px;
        padding-left: 90px;

        @include response(1023) {
            padding-left: 60px;
        }

        @include response(767) {
            padding-left: 15px;
        }
    }

    &__section {
        margin: 0 auto;
        padding: 0 20px;
        width: 100%;
        max-width: 1000px;
    }

    &__thumbs {
        margin: 0 auto;
        padding: 0 50px;
        max-width: 1440px;
        display: grid;
        grid-gap: 15px;
        margin-bottom: 100px;
        grid-template-columns: repeat(5, 1fr);
        @include response(1023) {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 10px;
            margin-bottom: 80px;
        }
        @include response(767) {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 15px;
        }
    }

    &__thumb {
        @include flex(column, nowrap, center, center);
        border: 1px solid #e5e5e5;
        padding: 20px;
        height: 295px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);

        @include response(767) {
            height: 225px;
            padding: 20px 0;
        }
        @include response(413) {
            height: 200px;
        }
    }

    &__caption {
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        color: $red-400;

        @include response(767) {
            font-size: 18px;
            line-height: 24px;
        }

        @include response(413) {
            font-size: 14px;
            line-height: normal
        }

        @include response(374) {
            font-size: 12px;
        }
    }

    &__icon {
        margin-bottom: 15px;
        max-width: 115px;
        max-height: 115px;

        @include response(1023) {
            max-width: 115px;
            max-height: 115px;
        }

        @include response(767) {
            margin-bottom: 10px;
            max-width: 90px;
            max-height: 90px;
        }
    }

    &__slider {
        position: relative;
        background-color: $red-600;
        padding: 45px 0;
        margin-bottom: 100px;

        &--wrapper {
            // max-width: 960px;
            width: 100%;
            margin: 0 auto;
        }

        @include response(1023) {
            margin-bottom: 50px;
            padding: 45px 78px;
        }

        @include response(767) {
            padding: 45px 0;
            margin-bottom: 40px;
        }
    }

    &__navigation {
        z-index: 1;
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        @include flex(row, wrap, space-between, center);

        @include response(1023) {
            padding: 0 20px;
        }

        @include response(767) {
            display: none;
        }
    }

    &__btn {
        @include flex(row, wrap, center, center);
        width: 38px;
        height: 38px;
        border: 2px solid #fff;
        pointer-events: all;
        color: #fff;

        svg {
            transform: scale(1);
            transition: all $transition;
            width: 7px;
            height: 14px;
            will-change: transform;
        }

        @include desktop-hover {
            svg {
                transform: scale(1.5);
            }
        }
    }


    .slick-dots {
        @include flex(row, wrap, center, center);
        margin-top: 28px;

        li {
            width: 12px;
            height: 12px;
            border: 1px solid #fff;
            margin: 0 6px;
            font-size: 0;

            &.slick-active {
                background-color: #fff;
            }
        }
    }

    &__item {
        list-style: auto;
        font-weight: bold;

        &-cities {
            margin-left: 25px;
            margin-top: 5px;
            padding: initial;
            font-size: 16px;
            font-weight: normal;
        }
        &-city {
            list-style: disc;
        }
    }
}
