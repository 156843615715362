.card-template {
  @include flex(row, nowrap, flex-start, center);
  min-height: 286px;
  box-shadow: 0px 4px 20px rgba(68, 68, 83, 0.16);
  background-color: $white;

  &__img {
    width: 260px;
    height: 100%;
    object-fit: cover;

    @include response(767) {
      width: 100%;
      height: 180px;
      margin-bottom: 15px;
    }
  }
  &__text {
    @include flex(column, nowrap, space-between, flex-start);
    padding: 70px 25px;
    height: 100%;
    flex: 1 1 auto;
    @include response(767) {
      padding: 25px;
      width: 100%;
      padding-top: 0;
      flex: none;
      height: auto;
    }
  }
  &__title {
    @include font-size(28, 34);
    margin-bottom: 24px;
    font-weight: 400;
    color: $red-400;
    @include mobile576 {
      margin-bottom: 5px;
    }
  }

  &__link {
    @include flex(row, nowrap, flex-start, center);
    @include font-size(16, 34);
    font-weight: 500;
    color: $dark-gray-600;
    transition: all .3s ease-in-out;

    &::before {
      content: '/\00a0';
      display: block;
      color: $red-100;
    }

  }

  @include response(767) {
    @include flex(column, nowrap, flex-start, flex-start);
  }

  @include desktop-hover {
    .card-template__link {
      color: $red-500;
    }
  }
}