.search-form {
    position: relative;
    left: 50%;
    margin-bottom: 45px;
    padding: 58px 0 42px;
    width: 100vw;
    background: #F9F9FA;
    transform: translate3d(-50%, 0, 0);

    &__wrapper {
        @extend %container;

        @include response(767) {
            max-width: 100%;
        }
    }

    &__section {
        @include flex(row, nowrap, space-between, center);
        position: relative;
        margin-bottom: 20px;
        height: 60px;
        background-color: #fff;
    }

    &__label {
        flex: 1 1 auto;
        margin-right: 10px;
        height: 100%;
        background-color: #fff;

        svg {
            pointer-events: none;
            position: absolute;
            left: 22px;
            top: 50%;
            z-index: 100;
            width: 16px;
            height: 16px;
            transform: translate3d(0, -50%, 0) scale(-1, 1);
        }
        input {
            outline: none;
            border: none;
            padding-left: 58px;
            width: 100%;
            height: 100%;
            font-weight: 400;
            font-size: 13px;
            line-height: 28px;
            color: #314C6E;
        }

        @include response(767) {
            margin-right: 0;

            input {
                padding-left: 15px;
            }

            svg {
                @include response(767) {
                    left: auto;
                    right: 25px;
                    width: 22px;
                    height: 22px;
                    color: #fff;
                    transform: translate3d(0, -50%, 0) scale(1, 1);
                }
            }
        }
    }


    &__btn {
        @extend %btn;
        flex: 0 0 182px;
        height: 100%;

        @include response(767) {
            flex: 0 0 90px;
            clip-path: polygon(33% 0%, 100% 0, 100% 100%, 0% 100%);
            min-width: auto;

            p {
                display: none;
            }
        }
    }

    &__filters {
        @include flex(row, nowrap, flex-start, center);
        width: 100%;

        p {
            @include font(12, false, false, 28, 400);
            margin-right: 15px;
        }

        @include response(767) {
            padding: 0 10px;
        }
    }

    &__list {
        @include flex(row, nowrap, flex-start, center);
    }

    &__radio {
        @include flex(row, nowrap, flex-start, center);
        cursor: pointer;


        &:not(:last-child) {
            margin-right: 10px;

            @include response(767) {
                margin-right: 1em;
            }
        }

        input {
            position: relative;
            flex: 0 0 16px;
            margin-right: 5px;
            outline: none;
            border: 0.5px solid #8EA4C0;
            border-radius: 50%;
            width: 16px;
            height: 16px;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                display: block;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: $red-500;
                transform: translate3d(-50%, -50%, 0) scale(0);
                transition: transform 0.3s ease-in-out;
            }

            &:checked {
                &::before {
                    transform: translate3d(-50%, -50%, 0) scale(1);
                }
            }

            @include response(370) {
                width: 10px;
                height: 10px;
                flex: 0 0 10px;

                &::before {
                    width: 5px;
                    height: 5px;
                }
            }
        }

        span {
            @include font(11, false, false, 11, 400);
        }

    }

    @include response(1023) {
        padding: 35px 0 30px;
    }

    @include response(767) {
        padding: 2em 0 3em;
        margin-bottom: 3em;
    }
}
