@function max($numbers...) {
	@return m#{a}x(#{$numbers});
}

@function min($numbers...) {
	@return m#{i}n(#{$numbers});
}

@mixin supports-safe-area-insets {
	// stylelint-disable-next-line
	@supports (padding: max(0px)) {
		@content;
	}
}

@mixin hover {
	.has-hover &:not([disabled]):hover {
		@content;
	}
}

@mixin active-hover {
	.has-hover &.is-active:hover {
		@content;
	}
}

@mixin active {
	&.is-active {
		@content;
	}
}

@mixin disabled {
	&.is-disabled,
	&:disabled {
		@content;
	}
}

@mixin text-border($color, $border, $ieColor, $width: 1px, $ieWidth: 1px) {
	// stylelint-disable-next-line
	text-shadow: ($ieWidth) ($ieWidth) $border, 0 0 $border, $width ($ieWidth) $border, 0 $width $border;
	color: $ieColor;

	@supports (-webkit-text-stroke: $width $border) {
		text-shadow: none;
		// stylelint-disable-next-line
		-webkit-text-stroke: $width $border;
		color: $color;
	}
}

@mixin font-face($url, $font-family, $font-weight, $font-style) {
	@font-face {
		src:
			url($url + ".woff2") format("woff2"),
			url($url + ".woff") format("woff");
		font-family: $font-family;
		font-weight: $font-weight;
		font-style: $font-style;
		font-display: swap;
	}
}

@mixin retina {
	// stylelint-disable-next-line media-feature-name-no-vendor-prefix
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@content;
	}
}

/* stylelint-disable */
@mixin placeholder {
	&::-webkit-input-placeholder {
		// WebKit, Blink, Edge
		@content;
	}

	&::-moz-placeholder {
		// Firefox 19+
		@content;
	}

	&:-ms-input-placeholder {
		// Internet Explorer 10-11
		@content;
	}
}
/* stylelint-enable */
// для установления ширины и высоты
@mixin box($width, $height: $width) {
	width: $width;
	height: $height;
}

// flexbox
@mixin flex-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-center-column {
	@include flex-center;
	flex-direction: column;
}

@mixin flex-center-vert {
	display: flex;
	align-items: center;
}

@mixin flex-center-horiz {
	display: flex;
	justify-content: center;
}

// font-face
@mixin font-face($name, $path, $weight: normal, $style: normal) {
	@font-face {
			font-family: quote($name);
			src: url('#{$path}.eot');
			src: url('#{$path}.eot?#iefix') format('embedded-opentype'),
			url('#{$path}.woff2') format('woff2'),
			url('#{$path}.woff') format('woff'),
			url('#{$path}.ttf') format('truetype');
			font-weight: $weight;
			font-style: $style;
			font-display: swap;
	}
}

// font-size, line-height, letter-spacing
@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
	font-size: $font-size * 1px;
	// font-size: $font-size * 0.1rem;
	// example using rem values and 62.5% font-size so 1rem = 10px

	@if $line-height==normal {
			line-height: normal;
	} @else {
			line-height: $line-height / $font-size;
	}

	@if $letter-spacing==normal {
			letter-spacing: normal;
	} @else {
			letter-spacing: #{$letter-spacing / $font-size}em;
	}
}

// media queries
@mixin tablet1024 {
	@media only screen and (max-width: $tablet1024 * 1px) {
			@content;
	}
}

@mixin tablet992 {
	@media only screen and (max-width: $tablet992 * 1px) {
			@content;
	}
}

@mixin tablet768 {
	@media only screen and (max-width: $tablet768 * 1px) {
			@content;
	}
}

@mixin mobile576 {
	@media only screen and (max-width: $mobile576 * 1px) {
			@content;
	}
}

@mixin mobile375 {
	@media only screen and (max-width: $mobile375 * 1px) {
			@content;
	}
}

@mixin mobile360 {
	@media only screen and (max-width: $mobile360 * 1px) {
			@content;
	}
}

@mixin desktop-hover() {
	@media only screen and (min-width: 1025px) {
		&:hover {
			@content;
		}
	}
}

@mixin flex($row: row, $wrap: wrap, $justify: center, $align: center) {
	display: flex;
	flex-flow: $row $wrap;
	align-items: $align;
	justify-content: $justify;
}

@mixin response($size) {
  @media only screen and (max-width: $size + 'px') {
    @content;
  }
}

@mixin min-response($size) {
    @media only screen and (min-width: $size + 'px') {
      @content;
    }
}

@mixin font($size: false, $color: false, $ls: false, $lh: false, $weight: false) {
    font-weight: 400;
    @if $size {
        font-size: $size + px;

        @include response(767) {
            font-size: $size / 10 + rem;
        }
    }
    @if $color {
        color: $color;
    }
    @if $ls {
        letter-spacing: $ls / $size + em;
    }
    @if $lh {
        line-height:  $lh / $size + em;
    }
    @if $weight {
        font-weight: $weight;
    }
}
