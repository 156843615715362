.item {
    position: relative;
    border-bottom: 0.5px solid #D9D9D9;
    padding: 30px 0 ;
    width: 100%;

    &__icon {
        @include flex(row, wrap, center, center);
        flex: 0 0 65px;
        margin-right: 28px;
        border: 0.5px solid #314C6E;
        border-radius: 3px;
        width: 65px;
        height: 65px;
        padding: 12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include response(1200) {
            margin-right: 20px;
        }

        @include response(767) {
            display: none;
        }
    }

    &__section {
        @include flex(row, nowrap, space-between, flex-start);

        @include response(767) {
            @include flex(column, wrap, flex-start, flex-start);
        }
    }

    &__inner {
        // @include flex(row, wrap, space-between, flex-start);
        flex: 1 1 auto;

        @include min-response(767) {
            display: grid;
            grid-row-gap: 0;
            grid-column-gap: 25px;
            grid-template-columns: repeat(12, 1fr);
        }

        @include response(1023) {
            padding-right: 30px;
            width: 100%;
        }

    }

    &__content {
        // width:  100%;
        // max-width: 440px;

        grid-column: span 6;

        h3 {
            margin-bottom: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        @include response(1023) {
            max-width: 200px;
        }

        @include response(767) {
            max-width: 100%;
            margin-bottom: 12px;
        }
    }

    &__link {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: $red-500;
        transition: color 0.3s ease-in-out;
        grid-column: span 3;
        text-align: right;

        @include desktop-hover {
            color: $red-800;
        }

        // @include response(1023) {
        //     position: absolute;
        //     left: 0;
        //     top: calc(100% + 20px);
        // }

        @include response(767) {
            position: absolute;
            left: 0;
            top: calc(100% + 15px);
        }
    }

    &__author {
        margin: 0 10px;
        // width: 100%;
        // max-width: 210px;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;

        grid-column: span 3;

        @include response(1023) {
            margin-right: 0;
            // max-width: 160px;
        }

        @include response(767) {
            margin-left: 0;
            // max-width: 100%;
            order: 1;
        }
    }

    &__place {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        margin-top: 12px;
        grid-column: span 12;

        @include response(767) {
            margin-top: 0;
            margin-bottom: 12px;
        }
    }

    @include response(1023) {
        margin-bottom: 48px;
        padding: 20px 0 ;
    }

    @include response(767) {
        margin-bottom: 6.5em;
        padding: 0.5em 0 1.2em;
    }

}
