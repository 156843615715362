.rules {
    &__wrapper {
      @extend %container;
      margin: 0 auto;
        width: 100%;
      padding-bottom: 55px;


      @include response(767) {
        padding: 20px 0 40px;
      }
    }

    .container {
        @include response(1200) {
          width: 100%;
          max-width: 100%;
        }

        @include response(767) {
          max-width: 100%;
        }
      }

    &__section {
      @include flex(row, wrap, space-between, center);
      margin-bottom: 35px;
    }

    &__title {
      @include font-size(40, 25);
      font-weight: 300;
      line-height: normal;
      color: $red-500;
      width: calc(100% - 200px);

      @include mobile576 {
          @include font-size(40, 44);
          margin-bottom: 15px;
          width: 100%;
      }
    }

    &__item {
        h2 {
            margin-bottom: 25px;
            @include font-size(22, 33);
            font-weight: 500;
            @include mobile576 {
                margin-bottom: 15px;
            }
        }

        p {
            @include font-size(17, 32);
        }

        &:not(:last-child) {
            margin-bottom: 30px;

            @include mobile576 {
                margin-bottom: 20px;
            }
        }
    }


    &__list {
        width: 100%;
    }

  }

