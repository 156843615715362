.video-js {

	&.vjs-youtube-mobile {
		.vjs-big-play-button {
			display: block;
		}
	}
	
	.vjs-big-play-button {
		transform: translate3d(-50%, -50%, 0);
		position: absolute;
		left: 50%;
		font-size: 5em;
		top: 50%;
		width: 74px;
		height: 74px;
		background: #e40a13;
		border-radius: 0;
		border: none;
		transition: all $transition;
		display: block;

		.vjs-icon-placeholder {
			position: relative;
			display: block;
			height: 100%;
			transition: all $transition;
			&::before {
				position: absolute;
				transition: all $transition;
				top: 50%;
				height: auto;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}


	.vjs-control-bar {
		bottom: 2.5em;
		background: none;

		@include response(767) {
			bottom: 0.5em;
		}
	}
	.vjs-fullscreen-control,
	.vjs-picture-in-picture-control,
	.vjs-volume-panel,
	.vjs-volume-panel-horizontal,
	.vjs-play-control,
	.vjs-button,
	.vjs-paused {
		display: none;
	}
	.vjs-play-progress,
 	.vjs-load-progress {
		background: #e40a13;
		div {
			background: #fff;
		}

		&::before {
			display: none !important;
		}
	 }
	.vjs-progress-control .vjs-progress-holder {
		margin: 0 4em;
		background: #fff;

		@include response(767) {
			margin: 0 2em;
		}
	}
	.vjs-remaining-time.vjs-time-control.vjs-control {
		@include flex(row, wrap, center, center);
		position: absolute;
    right: 3em;
		top: -1.85em;
		line-height: normal;
    font-size: 1.4em;
    padding: 0 1em;
		background: rgba(0, 0, 0, 0.5);

		@include response(767) {
			font-size: 0.9em;
			height: 20px;
			padding: 0 7px;
			right: 2.4em;
			top: -1.55em;
		}
	}

	.vjs-progress-control {
		&:hover {
			.vjs-progress-holder {
				font-size: inherit;
			}
			.vjs-time-tooltip,
			.vjs-mouse-display {
				font-size: 1em;
			}
		}
	}
}

.vjs-controls-disabled .vjs-big-play-button,
.vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-big-play-button,
.vjs-error .vjs-big-play-button {
	display: none !important;
}

.product__list {
	.activity__v-player-wrap {
		max-width: 279px;
		width: 100%;
		margin-left: 0;

		@include response(767) {
			max-width: 216px;
		}
	}

	.video-js {
		.vjs-big-play-button {
			transform: translate3d(-50%, -50%, 0);
			position: absolute;
			left: 50%;
			font-size: 3em;
			top: 50%;
			width: 34px;
			height: 34px;
			background: #e40a13;
			border-radius: 0;
			border: none;
			transition: all $transition;
		}
	}

	.vjs-control-bar {
		bottom: -0.2em;
		background: none;

		@include response(767) {
			bottom: 0.5em;
		}
	}

	.vjs-progress-control .vjs-progress-holder {
		margin: 0 1.3em;
		height: 0.1em;
		background: #fff;

		@include response(767) {
			margin: 0 1.3em;
		}
	}

	.vjs-remaining-time.vjs-time-control.vjs-control {
		@include flex(row, wrap, center, center);
    right: 2em;
    top: -0.85em;
    font-size: 0.6em;
    padding: 0 0.8em;
    height: 15px;
	}
}
.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button {
	transition: all $transition;
}
.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
	border-color: #fff;
	background-color: #73859f;
	background-color: rgba(115, 133, 159, 0.5);
	// transition: all 0s;
}