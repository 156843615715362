.security {
  width: 100%;
  &__wrapper {
    padding: 50px 0 90px;
    max-width: 960px;
	  width: 100%;
    margin: 0 auto;

    @include response(1023) {
      max-width: 100%;
    }
    @include response(767) {
      max-width: 100%;
      padding: 40px 0 40px;
    }
  }

  .vacancies__section {
    padding: 0;
  }

  .activity__logo-wrap {
    margin-bottom: 100px;

    @include response(1023) {
      margin-bottom: 60px;
    }

    @include response(767) {
      margin-bottom: 40px;
    }
  }

  .docs__list {
    margin-bottom: 0;
  }

  .docs__item {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}