.slick-track {
  @include flex(row, nowrap, flex-start, stretch);
}
.slick-slide {
  height: auto;
  > div {
    height: 100%;
  }
}
.slide {
  @include flex(row, wrap, center, center);
  // width: 100%;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(68, 68, 83, 0.16);
  padding: 70px 50px 20px;
  width: 960px !important;
  margin: 0 70px;
  height: 100%;
  display: flex !important;

  @include response(1023) {
    max-width: 100%;
    margin: 0;
    padding: 40px 20px;
  }

  @include response(767) {
    padding: 80px 20px 15px;
  }

  &__wrapper {
    width: 100%;
  }

  &__quote {
    font-family: 'Impact', sans-serif;
    font-size: 94px;
    line-height: 34px;
    color: $red-500;
  }

  &__item {
    @include flex(row, nowrap, flex-start, center);
    border-top: 1px solid #e5e5e5;
    padding: 25px 0;
  }

  &__description {
    font-size: 22px;
    line-height: 42px;
    margin-bottom: 25px;
  }

  &__icon {
    object-fit: cover;
    overflow: hidden;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    flex: 0 0 103px;
    margin-right: 40px;
  }

  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  &__position {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }
}