.range {
    width: 100%;
    max-width: 115px;
    margin-top: -30px;

    &__wrap {
        @include flex(row, nowrap, space-between, center);
    }

    &__label {
        @include flex(row, nowrap, space-between, center);
        p {
            margin-right: 5px;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #727880;
        }

        input {
            border: none;
            border-bottom: 0.25px solid #959CA7;
            width: 30px;
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #314C6E;
        }
    }

    .separator {
        margin: 0 10px;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #314C6E;
    }

    .irs--round .irs-line {
        border-radius: 0;
        height: 1px;
        background-color: #D9D9D9;
    }

    .irs-handle {
        top: 31px;
        border: 1px solid #D9D9D9;
        width: 10px;
        height: 10px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        background: #FFFFFF;
        transition: border-color 0.3s ease-in-out;
        cursor: pointer;

        @include desktop-hover {
            border-color: #4D637E;
        }
    }

    .irs-bar {
        height: 1px;
        background-color: #4D637E;
    }
}
