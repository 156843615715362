.career {
  &__wrapper {
    padding: 50px 0 90px;
    max-width: 960px;
	  width: 100%;
    margin: 0 auto;

    @include response(1023) {
      max-width: 100%;
    }
    @include response(767) {
      max-width: 100%;
      padding: 40px 0 40px;
    }
  }

  .pages-main-screen {
    background-position: top;
  }

  &__lead {
    position: relative;
    font-weight: 400;
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 35px;

    &::after {
      margin-top: 35px;
      content: '';
      display: block;
      width: 110px;
      height: 3px;
      background: #e30613;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
  }

  &__text {
    @extend %textContainer;
    margin-bottom: 60px;
  }

  &__cover {
    position: relative;
    margin-bottom: 70px;
    padding-top: 56.25%;
    width: 100%;
    // height: 540px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include response(1023) {
      margin-bottom: 60px;
      // height: 387px;
    }

    @include response(767) {
      margin-bottom: 40px;
      // height: 233px;
    }
  }

  &__item {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    "title title title title"
    "icon text text text";

    &:not(:last-child) {
      margin-bottom: 60px;
    }

    @include response(1023) {
      padding: 0 55px;
    }

    @include response(767) {
      padding: 0 35px;
      grid-template-columns: 1fr;
      grid-template-areas:
      "icon"
      "title"
      "text";

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }

  &__icon {
    grid-area: icon;
    width: 120px;
    height: 100px;
    margin: 0 auto;
    margin-left: 40px;
    align-self: flex-start;

    @include response(1023) {
      margin-left: 0;
      margin-right: 34px;
    }

    @include response(767) {
      width: 95px;
      height: 80px;
      margin-bottom: 20px;
    }
  }

  &__heading {
    grid-column: 2 / 5;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;

    @include response(767) {
      grid-column: 1 / 1;
      line-height: normal;
      margin-bottom: 15px;
    }
  }

  &__tagline {
    margin-top: 10px;
    grid-area: text;
    font-size: 17px;
    line-height: 32px;

    @include response(767) {
      margin-top: 0;
    }
  }
}