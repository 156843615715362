// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS
$color-black: #000;
$color-white: #fff;
$color-gray: #eee;

// FONTS
// stylelint-disable-next-line
$font-primary: -apple-system, blinkmacsystemfont, "Segoe UI", roboto, oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;

// EASING TRANSITIONS
$transition: 0.3s ease-in-out;
$duration-normal: 0.4s;
$easing-back-out: cubic-bezier(0.25, 0.74, 0.22, 1.1); // http://cubic-bezier.com/#.25,.74,.22,1.1
$easing-back-in: cubic-bezier(0.47, -0.1, 0.5, -0.06); // http://cubic-bezier.com/#.47,-0.1,.5,-.06
$easing-out: ease-out;
$easing-in: ease-in;

// Settings
$desktop: 1025;
$desktop-xl: 1800;
$laptop: 1440;
$tablet: 768;
$mobile: 320;
$desktop-vh: 750;


//colors
$gray-600: #E5E5E5;
$dark-gray-100: #727880;
$dark-gray-300: #424F5F;
$dark-gray-400: #4D637E;
$dark-gray-500: #39577C;
$dark-gray-600: #314C6E;
$dark-gray-700: #304B6D;
$dark-gray-800: #10384F;
$red-100: #FE2638;
$red-200: #f9000a;
$red-400: #E30613;
$red-500: #E40A13;
$red-600: #BF111B;
$red-700: #A50716;
$red-800: #B7061D;
$white: #fff;
$white-500: #FCFCFC;
$white-400: #FDFDFD;
$black: #000;
$light-blue-100: #B7CCE6;
$light-blue-300: #8EA4C0;
$light-blue-400: #547EB4;
$blue: #3A86ED;
$green-200: #52B979;


// breakpoints
$desktop: 1280;
$tablet1024: 1024;
$tablet992: 992;
$tablet768: 768;
$mobile576: 576;
$mobile375: 413;
$mobile360: 374;