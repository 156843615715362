.contacts {
    padding: 20px 0 90px;
    @include tablet768 {
        padding: 15px 0 60px;
    }
    @include mobile576 {
        padding: 5px 0 40px;
    }

    &__title {
        margin-bottom: 15px;
        @include font-size(40, 25);
        font-weight: 300;
        color: $red-500;
        @include mobile576 {
            @include font-size(40, 44);
        }
    }

    &__desc {
        max-width: 864px;
        margin-bottom: 40px;
        @include font-size(17, 32);
        @include tablet768 {
            margin-bottom: 30px;
        }
    }

    &__address-block {
        padding: 0 97px;
        @include tablet1024 {
            padding: 0;
        }
    }

    &__address {
        display: flex;
        align-items: flex-start;
        gap: 60px;
        @include mobile576 {
            flex-direction: column;
            gap: 15px;
        }

        .address {
            &__title {
                max-width: 274px;
                width: 100%;
                @include font-size(20, 34);
                font-weight: 500;
                text-align: right;
                @include tablet1024 {
                    max-width: 160px;
                }
                @include mobile576 {
                    max-width: 100%;
                    text-align: left;
                }

                &--right {
                    text-align: right;
                }
            }

            &__info {
                max-width: 482px;
                @include font-size(17, 32);
            }

            &__email {
                color: $blue;
            }
        }
    }

    &__map {
        width: 100%;
        min-height: 250px;
        margin: 25px 0 40px;
        background-image: url(../images/about/map.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include tablet1024 {
            width: calc(100% + 80px);
            margin-left: -40px;
            padding: 30px 0 60px;
        }
        @include mobile576 {
            width: calc(100% + 30px);
            margin-left: -15px;
            padding: 20px 0 30px;
            background-position: 40% 50%;
        }
    }
}